import axios from './AxiosInstance'

export default {
  mailValidation: ({ name, email }) => {
    var formData = new FormData()
    formData.append('name', name)
    formData.append('email', email)
    return new Promise((resolve, reject) => {
      axios.currentCsrfToken().then(token => {
        // console.log(token)
        axios.httpClient({
          method: 'post',
          url: '/api/user_mail_validation',
          headers: {
            'X-HTTP-Method-Override': 'POST',
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': token
          },
          withCredentials: true,
          data: formData
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  createWithDevice: ({ formData }) => {
    return new Promise((resolve, reject) => {
      axios.currentCsrfToken().then(token => {
        axios.httpClient({
          method: 'post',
          url: '/api/user/create_with_device',
          headers: {
            'X-HTTP-Method-Override': 'POST',
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': token
          },
          withCredentials: true,
          data: formData
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  createPhoneDevice: ({ formData }) => {
    return new Promise((resolve, reject) => {
      axios.currentCsrfToken().then(token => {
        axios.httpClient({
          method: 'post',
          url: '/api/user/create_phone_device',
          headers: {
            'X-HTTP-Method-Override': 'POST',
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': token
          },
          withCredentials: true,
          data: formData
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  signin: ({ accountName, password }) => {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.append('login_id', accountName)
      formData.append('password', password)
      axios.currentCsrfToken().then(token => {
        axios.httpClient({
          method: 'post',
          url: '/api/authenticate/user_signin',
          headers: {
            'X-HTTP-Method-Override': 'POST',
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': token
          },
          withCredentials: true,
          data: formData
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  users_by_nb_signin: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: '/api/authenticate/nb_account_selection',
        headers: {
          'X-HTTP-Method-Override': 'GET',
        },
        withCredentials: true
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },

  signin_with_nb_sso: ({ user_id }) => {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.append('user_id', user_id)
      axios.currentCsrfToken().then(token => {
        axios.httpClient({
          method: 'post',
          url: '/api/authenticate/signin_with_nb_sso',
          headers: {
            'X-HTTP-Method-Override': 'POST',
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': token
          },
          withCredentials: true,
          data: formData
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  signout: () => {
    return new Promise((resolve, reject) => {
      axios.currentCsrfToken().then(token => {
        axios.httpClient({
          method: 'delete',
          url: '/api/authenticate/signout',
          headers: {
            'X-HTTP-Method-Override': 'DELETE',
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': token
          },
          withCredentials: true,
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  fetch: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: '/api/user',
        headers: {
          'X-HTTP-Method-Override': 'GET',
        },
        withCredentials: true
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },

  fetchCurrentSignin: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: '/api/authenticate/current_signin_user_and_device',
        headers: {
          'X-HTTP-Method-Override': 'GET',
        },
        withCredentials: true
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },

  sendMailValidation: ({ email }) => {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.append('email', email)
      axios.currentCsrfToken().then(token => {
        axios.httpClient({
          method: 'put',
          url: '/api/user',
          headers: {
            'X-HTTP-Method-Override': 'PUT',
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': token
          },
          withCredentials: true,
          data: formData
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  update: ({ formData }) => {
    return new Promise((resolve, reject) => {
      axios.currentCsrfToken().then(token => {
        axios.httpClient({
          method: 'put',
          url: '/api/user',
          headers: {
            'X-HTTP-Method-Override': 'PUT',
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': token
          },
          withCredentials: true,
          data: formData
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  updatePassword: ({ password, passwordConfirmation}) => {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.append('password', password)
      formData.append('password_confirmation', passwordConfirmation)
      axios.currentCsrfToken().then(token => {
        axios.httpClient({
          method: 'put',
          url: '/api/user/update_password',
          headers: {
            'X-HTTP-Method-Override': 'PUT',
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': token
          },
          withCredentials: true,
          data: formData
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  destroy: () => {
    return new Promise((resolve, reject) => {
      axios.currentCsrfToken().then(token => {
        axios.httpClient({
          method: 'delete',
          url: '/api/user',
          headers: {
            'X-HTTP-Method-Override': 'DELETE',
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': token
          },
          withCredentials: true,
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  account_result: ({code}) => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: '/api/user/select_account_result',
        headers: {
          'X-HTTP-Method-Override': 'GET',
        },
        withCredentials: true,
        params: { code: code }
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },

  fetch_narrativebook_account: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: '/api/user/narrativebook_account',
        headers: {
          'X-HTTP-Method-Override': 'GET',
        },
        withCredentials: true,
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },

  update_narrativebook_account: () => {
    return new Promise((resolve, reject) => {
      axios.currentCsrfToken().then(token => {
        axios.httpClient({
          method: 'put',
          url: '/api/user/connect_narrativebook',
          headers: {
            'X-HTTP-Method-Override': 'PUT',
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': token
          },
          withCredentials: true,
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  delete_device_relation: ({target_device_id}) => {
    return new Promise((resolve, reject) => {
      axios.currentCsrfToken().then(token => {
        axios.httpClient({
          method: 'delete',
          url: '/api/user/delete_device_relation',
          headers: {
            'X-HTTP-Method-Override': 'DELETE',
            'X-CSRF-TOKEN': token
          },
          withCredentials: true,
          params: { target_device_id: target_device_id }
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  revoke_zoom: () => {
    return new Promise((resolve, reject) => {
      axios.currentCsrfToken().then(token => {
        axios.httpClient({
          method: 'delete',
          url: '/api/user/revoke_zoom',
          headers: {
            'X-HTTP-Method-Override': 'DELETE',
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': token
          },
          withCredentials: true,
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    })
  }

}