<template>
  <div class="connect-zoom-account-view">
    <div class="header">
      <img class="title-icon" src="/images/hero-logotype.png" />
      <h1>Zoomアカウント接続結果</h1>
    </div>
    <div class="connection-result-column success" v-if="result == 'success'">
      <h2>Zoomアカウントと接続に成功しました。</h2>
      <div class="message">
        Zoomとのアカウントとの接続に成功しました。<br/>
        以後、ナラティブフォンでは接続したZoomアカウントを利用してビデオ通話を行います。
        <div class="device-type" v-if="this.type == 'android'">
          Androidデバイス設定: <span v-if="this.user.android_zoom_available">成功</span><span v-if="!this.user.android_zoom_available">失敗</span>
        </div>
        <div class="device-type" v-if="this.type == 'ios'">
          Androidデバイス設定: <span v-if="this.user.ios_zoom_available">成功</span><span v-if="!this.user.ios_zoom_available">失敗</span>
        </div>
      </div>
    </div>
    <div class="connection-result-column failed" v-if="result == 'failed'">
      <h2>Zoomの認証と接続に失敗しました。</h2>
      <div class="message">
        Zoomとのアカウントとの接続に失敗しました。<br/>
        この状態では、ナラティブフォンのユーザーとデバイスの登録が完了していますが、通話に必要なZoomアカウントとの接続が終わっていません。<br/>
        Zoom再接続のボタンを押して、もう一度トライしてください。<br/>
        ナラティブフォンのユーザーとデバイスの登録はナラティブフォンサーバーになされているので、はじめから試すときは「インポート」の操作で入力したユーザーを利用できます。
      </div>
      <div class="controll">
        <button class="retry-btn" @click="retryConnectZoom">Zoomに再接続する</button>
      </div>
    </div>
    <confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
    />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'
import ConfirmDialogControll from '@/mixin/ConfirmDialogControll.js'

export default{
  mixins: [ConfirmDialogControll],

  data () {
    return {
      result: '',
      type: '',
      user: {},
      device: {}
    }
  },

  components: {
    ConfirmDialog
  },

  watch: {
    '$route': { handler: 'storeQuery', immediate: true },
    // device: { handler: 'fireFinishedEvent', immediate: true }
  },

  methods: {
    storeQuery () {
      console.log(this.$route.query)
      if (this.$route.query.result) {
        this.result = this.$route.query.result
      }
      if (this.$route.query.type) {
        this.type = this.$route.query.type
      }
      if (this.$route.query.user) {
        var user = JSON.parse(this.$route.query.user)
        console.log(user)
        this.user = {
          id: user.id,
          name: user.name,
          account_name: user.account_name,
          email: user.email,
          nb_id: user.nb_id,
          nb_author_id: user.nb_author_id,
          nb_organization_id: user.nb_organization_id,
          narrative_phone_id: user.narrative_phone_id,
          ios_zoom_available: user.ios_zoom_available,
          android_zoom_available: user.android_zoom_available,
          description: user.description,
          icon_img_path: user.icon_img_path,
          state: user.state,
          state_jp: user.state_jp
        }
      }
      if (this.$route.query.phone_device) {
        var device = JSON.parse(this.$route.query.phone_device)
        this.device = {
          id: device.id,
          phone_device_id: device.phone_device_id,
          phone_device_description: device.phone_device_description,
          phone_device_token: device.phone_device_token,
          device_authenticate_token: device.device_authenticate_token,
          push_target: device.push_target,
          created_at: device.created_at,
          available: device.available
        }
      }
      if (this.user && this.device && (this.user.ios_zoom_available || this.user.android_zoom_available)) {
        this.fireFinishedEvent()
      }
    },

    retryConnectZoom () {
      if (this.type == 'ios') {
        var iosZoomAuthURL = location.protocol 
          + '//' 
          + location.host 
          + '/api/user/contact_zoom_auth?retry_type=ios'
        console.log('zoom auth url: ' + iosZoomAuthURL)
        window.location.href = iosZoomAuthURL
      } else if (this.type == 'android') {
        var androidZoomAuthURL = location.protocol 
          + '//' 
          + location.host 
          + '/api/user/contact_zoom_auth?retry_type=android'
        console.log('zoom auth url: ' + androidZoomAuthURL)
        window.location.href = androidZoomAuthURL
      } else {
        this.showNotifyDialog('Zoomへの接続失敗', '接続情報が消失しています。Zoomへの接続操作を継続できません。\n一度アプリを終了してから、次回は「インポート」の操作を行ってください。')
      }
    },

    fireFinishedEvent () {
      if (this.result == 'success') {
        if (this.$native) {
          var resultData = {
            user_id: this.user.id,
            narrative_phone_id: this.user.narrative_phone_id,
            ios_zoom_available: this.user.ios_zoom_available,
            android_zoom_available: this.user.android_zoom_available,
          }
          this.$native.event('zoomUserActivatedEvent', resultData)
        }
      } else {
        if (this.$native) {
          this.$native.event('zoomUserActivationFailedEvent')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/colorTable.scss';
.connect-zoom-account-view {
  .header {
    padding: 20px;
    text-align: center;
    img {
      display: block;
      margin: 10px auto;
      &.title-icon {
        width: 390px;
        @include mediaQuery('phone') {
          width: calc(100% - 40px);
        }
      }
    }
    h1 {
      font-size: 21px;
      color: #666;
    }
  }
  .connection-result-column {
    margin: 10px 20px;
    padding: 10px;
    border: 1px solid #ddd;
    h2 {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
    .message {
      line-height: 1.5em;
    }
    .controll {
      text-align: center;
      button.retry-btn {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: $zoom-main-color;
        font-weight: bold;
        color: #fff;
      }
    }
  }
}
</style>