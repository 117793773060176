import axios from 'axios'
// import axiosCookiejarSupport from 'axios-cookiejar-support'
// import toughCookie from 'tough-cookie'
// const cookiejar = new toughCookie.CookieJar()
// // Axiosにプラグイン注入
// axiosCookiejarSupport(axios)

// csrfトークンを自動更新
axios.defaults.xsrfCookieName = 'CSRF-TOKEN'
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
axios.defaults.withCredentials = true

// NOTE !!!!!!! バックエンドへの回送用URLを環境で返られるようにすべし !!!!!!!!!
let httpClient = axios.create({
  // jar: cookiejar, // cookiejarを有効化する
  withCredentials: true, // 依然として必要
  credentials: 'include',
  // baseURL: process.env.VUE_APP_BASE_URL
})

export default {
  httpClient,

  // NOTE !!!!!!! バックエンドへの回送用URLを環境で返られるようにすべし !!!!!!!!!
  currentCsrfToken () {
    return new Promise((resolve, reject) => {
      const client = axios.create({
        // jar: cookiejar, // cookiejarを有効化する
        withCredentials: true, // 依然として必要
        credentials: 'include',
        // baseURL: process.env.VUE_APP_BASE_URL
      })
      client.get('/api/sessions', { withCredentials: true }).then(res => {
        // console.log(res.headers["x-csrf-token"])
        // console.log(res.headers.cookie)
        const token = res.headers["x-csrf-token"]
        // const parser = new DOMParser()
        // const document = parser.parseFromString(res.data, 'text/html')
        // const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        // console.log(csrfToken)
        // resolve(csrfToken)
        resolve(token)
      }).catch(error => {
        reject(error)
      })
    }).catch(error => {
      console.error(error)
    })
  }
}
