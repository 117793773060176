<template>
  <div class="zoom-document container">
    <div class="contents">
      <div class="main-contents jp" v-if="displayLang === 'jp'">
        <h1>Zoom利用のための解説<a href="/zoom_document/en" class="switch-lang">English</a></h1>
        <div class="content-block">
          ナラティブフォンは、ナラティブブックサービスの利用者に向けた、電話のように発信者と受信者の間でビデオ通話を行うアプリです。<br/>
          このため、動作確認のためには最低限2台のデバイスと、2つのナラティブブックのアカウントが必要となります。<br/>
          レビューのためのアカウントは発信者用と受信者用の2つのアカウントを用意しています。<br/>
          また、通話のためには連絡先の登録などが必要となるため、すでに連絡先登録してあるアカウントをインポートして利用します。
        </div>
        <h3>発信者側アカウントの用意</h3>
        <div class="content-block">
          インストール直後には、カメラ及びマイクへのアクセス権限要求がされるので、許可をしてください。<br/>
          <br/>
          初回起動時は、利用規約への同意画面が表示されます。<br/>
          チェックボックスにチェックを入れて、「次へ」ボタンをタップしてください。<br/>
        </div>
        <div class="content-block">
          <h3>ナラティブブックアカウントを利用した新規アカウント作成</h3>
          <ol>
            <li>
              利用規約に同意する<br/>
              利用規約の同意チェックボックスをONにして「次へ」をタップ
            </li>
            <li>アカウント作成方法選択画面で、「ナラティブブックアカウントから作成」をタップ</li>
            <li>ナラティブブックのログイン画面が表示されるので、ナラティブブックのログインID(NBID)とパスワードを入力</li>
            <li>
              選択可能なアカウント一覧が表示されるので、利用したいアカウントを選択<br/>
              <span class="memo">(一般利用者の場合は一つだけ、施設職員の場合は、施設職員アカウントと一般利用者アカウント、施設管理者権限を持つ施設職員アカウントの場合は、施設代表アカウントと施設職員アカウント、一般利用者アカウントが選択可能となります)</span>
            </li>
            <li>アカウントとデバイスの登録完了画面が表示され、Zoomの認証ボタンが表示されます。</li>
          </ol>
        </div>
        <div class="content-block">
          <h3>IDとパスワードを利用した新規アカウント作成</h3>
          <ol>
            <li>
              利用規約に同意する<br/>
              利用規約の同意チェックボックスをONにして「次へ」をタップ
            </li>
            <li>アカウント作成方法選択画面で、「IDとパスワード用いた新規アカウント作成」をタップ</li>
            <li>
              情報入力画面で必要な情報を入力<br/>
              <span class="memo">
                ログインIDは半角英数字と記号!@._/-+が利用可能で、6文字以上です。<br/>
                パスワードは半角英数字と記号!@._/-+が利用可能で、6文字以上です。<br/>
                名前とメールアドレスを入力した時点で、「認証番号取得」のボタンをタップすると、メールアドレスの認証メールが入力されたメールアドレス宛に送信されます。<br/>
                認証メールの本文にある8桁の認証番号を「メール認証番号」に入力します。<br/>
              </span>
            </li>
            <li>入力が終わったら「送信」ボタンをタップします。</li>
            <li>アカウントとデバイスの登録完了画面が表示され、Zoomの認証ボタンが表示されます。</li>
          </ol>
        </div>
        <div class="content-block">
          <h3>ナラティブブックアカウントを利用して作成した既存アカウントのインポート</h3>
          <ol>
            <li>
              利用規約に同意する<br/>
              利用規約の同意チェックボックスをONにして「次へ」をタップ
            </li>
            <li>アカウント作成方法選択画面で、「ナラティブブックアカウントからインポート」をタップ</li>
            <li>ナラティブブックのログイン画面が表示されるので、ナラティブブックのログインID(NBID)とパスワードを入力</li>
            <li>インポート可能なアカウント一覧が表示されるので、利用したいアカウントを選択</li>
            <li>
              アカウントとデバイスの登録完了画面が表示され、(必要であれば)Zoomの認証ボタンが表示されます。<br/>
              <span class="memo">
                *iOSのアプリで作成されたアカウントをiOSのアプリでインポートする場合、Androidのアプリで作成されたアカウントをAndroidのアプリでインポートする場合はZoomの認証は省略されます。
              </span>
            </li>
          </ol>
        </div>
        <div class="content-block">
          <h3>IDとパスワードを利用して作成した既存アカウントのインポート</h3>
          <ol>
            <li>
              利用規約に同意する<br/>
              利用規約の同意チェックボックスをONにして「次へ」をタップ
            </li>
            <li>アカウント作成方法選択画面で、「IDとパスワードで作成されたアカウントをインポート」をタップ</li>
            <li>ログインIDとパスワードを入力して「次へ」をタップ</li>
            <li>インポート可能なアカウント一覧が表示されるので、利用したいアカウントを選択</li>
            <li>
              アカウントとデバイスの登録完了画面が表示され、(必要であれば)Zoomの認証ボタンが表示されます。<br/>
              <span class="memo">
                *iOSのアプリで作成されたアカウントをiOSのアプリでインポートする場合、Androidのアプリで作成されたアカウントをAndroidのアプリでインポートする場合はZoomの認証は省略されます。
              </span>
            </li>
          </ol>
        </div>
        <div class="content-block">
          <h3>Zoomの認証</h3>
          <p>上記操作を行ったあと、(必要であれば)Zoom認証に進みます。</p>
          <ol>
            <li>画面内の「Zoomに接続する」をタップ</li>
            <li>Zoomの認証ページに遷移するので、Zoomアカウントでログインしてアプリの認証を行ってください</li>
            <li>認証が完了すると、アプリの基本画面に遷移します。</li>
          </ol>
        </div>
        <div class="content-block">
          <h3>アカウントの削除</h3>
          <p>アカウントの情報を完全にサーバーから削除するためには、以下の手順を取ってください。</p>
          <ol>
            <li>「設定」画面の上部にある「高度な設定」をタップするか、https://phone.narrativebook.jp/user_configにアクセスする</li>
            <li>「ナラティブブックでログイン」をタップして、ログインIDとパスワードを入力</li>
            <li>アカウントの状況によっては、対象となるアカウントを選択する必要があります。</li>
            <li>高度な設定画面の「基本登録情報」の「削除」ボタンをタップすると、サーバー上からアカウント情報が完全に削除されます。</li>
          </ol>
        </div>
      </div>
      <div class="main-contents en" v-if="displayLang === 'en'">
        <h1>Explanation for using Zoom<a href="/zoom_document" class="switch-lang">日本語</a></h1>
        <h2>Signin for Narrativebook</h2>
        <div class="content-block">
          With Narrative Phone, you need to link the account you created and the device you registered with your Zoom account.<br/>
          Even if you use the same Narrative Phone account, you will need to authenticate your Zoom account on both the iOS and Android versions of the app.
        </div>
        <h3>Creating a new account</h3>
        <div class="content-block">
          The account addition screen will be displayed when you start up Narrative Phone for the first time and when you press the "Add account(アカウント追加)" button on the settings screen.<br/>
          There are four ways to add an account:
          <ol>
            <li>Create a new account using Narrative Book Account</li>
            <li>Create a new account using ID and password</li>
            <li>Importing an existing account created using a narrative book account</li>
            <li>Importing an existing account created using an ID and password</li>
          </ol>
          No matter which method you use, after the account and device registration is completed, if Zoom authentication has not been performed, you will need to perform Zoom authentication.<br/>
          There are four different methods for getting to the screen that requires Zoom authentication.
        </div>
        <div class="content-block">
          <h3>Create a new account using Narrative Book Account</h3>
          <ol>
            <li>
              Agree to the terms of use<br/>
              <span class="memo">Turn on the Agree to Terms of Use checkbox and tap "Next(次へ)"</span>
            </li>
            <li>On the account creation method selection screen, tap "Create from Narrative Book Account(ナラティブブックアカウントから作成)"</li>
            <li>The Narrative Book login screen will be displayed, so enter the Narrative Book login ID (NBID) and password.</li>
            <li>
              A list of available accounts will be displayed, so select the account you want to use.<br/>
              <span class="memo">(For general users, only one account; for facility staff, facility staff account and general user account; for facility staff accounts with facility administrator privileges, facility representative account, facility staff account, and general user account) account can be selected)</span>
            </li>
            <li>The account and device registration completion screen will be displayed, and the Zoom authentication button will be displayed.</li>
          </ol>
        </div>
        <div class="content-block">
          <h3>Create a new account using ID and password</h3>
          <ol>
            <li>
              Agree to the terms of use<br/>
              <span class="memo">Turn on the Agree to Terms of Use checkbox and tap "Next(次へ)"</span>
            </li>
            <li>On the account creation method selection screen, tap "Create a new account using ID and password(IDとパスワード用いた新規アカウント作成)"</li>
            <li>
              Enter the necessary information on the information input screen<br/>
              <span class="memo">
                The login ID can be half-width alphanumeric characters and symbols !@._/-+, and must be 6 or more characters.<br/>
                The password can be half-width alphanumeric characters and symbols !@._/-+, and must be at least 6 characters.<br/>
                Once you have entered your name and email address, tap the "Obtain verification number(認証番号取得)" button and a verification email will be sent to the email address you entered.<br/>
                Enter the 8-digit verification number found in the body of the verification email in "Email Verification Number".
              </span>
            </li>
            <li>When you have finished entering your information, tap the "Send(送信)" button.</li>
            <li>The account and device registration completion screen will be displayed, and the Zoom authentication button will be displayed.</li>
          </ol>
        </div>
        <div class="content-block">
          <h3>Importing an existing account created using a narrative book account</h3>
          <ol>
            <li>
              Agree to the terms of use<br/>
              <span class="memo">Turn on the Agree to Terms of Use checkbox and tap "Next(次へ)"</span>
            </li>
            <li>On the account creation method selection screen, tap "Import from Narrative Book Account(ナラティブブックアカウントからインポート)"</li>
            <li>The Narrative Book login screen will be displayed, so enter the Narrative Book login ID (NBID) and password.</li>
            <li>A list of accounts that can be imported will be displayed, so select the account you want to use.</li>
            <li>
              The account and device registration completion screen will be displayed, and the Zoom authentication button will be displayed (if necessary).<br/>
              <span class="memo">*Zoom authentication is omitted when importing an account created with an iOS app using an iOS app, or when importing an account created using an Android app using an Android app.</span>
            </li>
          </ol>
        </div>
        <div class="content-block">
          <h3>Importing an existing account created using an ID and password</h3>
          <ol>
            <li>
              Agree to the terms of use<br/>
              <span class="memo">Turn on the Agree to Terms of Use checkbox and tap "Next(次へ)"</span>
            </li>
            <li>On the account creation method selection screen, tap "Import account created with ID and password"</li>
            <li>Enter your login ID and password and tap "Next(次へ)"</li>
            <li>A list of accounts that can be imported will be displayed, so select the account you want to use.</li>
            <li>
              The account and device registration completion screen will be displayed, and the Zoom authentication button will be displayed (if necessary).<br/>
              <span class="memo">*Zoom authentication is omitted when importing an account created with an iOS app using an iOS app, or when importing an account created using an Android app using an Android app.</span>
            </li>
          </ol>
        </div>
        <div class="content-block">
          <h3>Zoom authentication</h3>
          After performing the above steps, proceed to Zoom authentication (if necessary).
          <ol>
            <li>Tap "Connect to Zoom(Zoomに接続する)" on the screen</li>
            <li>You will be redirected to the Zoom authentication page, so log in with your Zoom account and authenticate the app.</li>
            <li>Once authentication is complete, you will be redirected to the basic screen of the app.</li>
          </ol>
        </div>
        <div class="content-block">
          <h3>Deleting an account</h3>
          <p>Follow the steps below to completely delete your account information from the server. </p>
          <ol>
            <li>Tap "Advanced Settings"(高度な設定) at the top of the "Settings" screen, or access https://phone.narrativebook.jp/user_config</li>
            <li>Tap "Log in with Narrative Book"(ナラティブブックでログイン) and enter your login ID and password</li>
            <li>Depending on the status of your account, you may need to select the target account. </li>
            <li>Tap the "Delete"(削除) button under "Basic Registration Information"(基本登録情報) on the advanced settings screen to completely delete your account information from the server. </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ZoomDocument',

  props: {
    lang: String
  },

  data () {
    return {
      displayLang: 'jp'
    }
  },

  watch: {
    '$attrs': { handler: 'initLang', immediate: true }
  },

  methods: {
    initLang () {
      if (this.lang[0] === 'en') {
        this.displayLang = 'en'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$breakpoints: (
  "phone": "screen and (max-width: 420px)",
  "tablet": "screen and (max-width: 1023px)",
  "pc": "screen and (min-width: 1024px)"
);

// メディアクエリ
@mixin mediaQuery($breakpoint: sp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

.zoom-document {
  background-color: #eee;
  .contents {
    min-height: calc(100% - 50px - 40px - 132px);
    padding: 20px 60px;
    background-color: #fff;
    @include mediaQuery('phone') {
      padding: 10px 20px;
    }
    font-size: 1.1em;
    h1 {
      margin: 0;
      padding: 0;
      font-size: 1.4em;
      font-weight: bold;
      .switch-lang {
        padding-left: 20px;
        font-size: 12px;
        font-weight: normal;
      }
    }
    h2 {
      margin: 0;
      margin-top: 30px;
      font-size: 1.1em;
      font-weight: bold;
    }
    h3 {
      margin: 0;
      margin-top: 10px;
      font-size: 1em;
      font-weight: bold;
    }
    .content-block {
      margin: 10px 0;
      padding: 10px;
      border: 1px solid #aaa;
    }
    span.memo {
      font-size: 0.9em;
    }
    p {
      margin: 5px 0;
    }
    p.right-align {
      text-align: right;
    }
    ol {
      margin: 10px 0;
      padding-inline-start: 20px;
      @include mediaQuery('phone') {
        padding-inline-start: 10px;
      }
      li {
        margin: 8px;
        div.notify {
          margin: 5px 0;
          padding: 5px;
          border: 1px solid #c85554;
          font-size: 0.9em;
        }
      }
    }
    ul {
      list-style-type: none;
      padding-inline-start: 10px;
      li {
        margin: 8px;
      }
    }
    .main-contents.en {
      word-break: normal;
    }
    .fig {
      margin: 10px 0;
      padding: 10px;
      border: 1px solid #aaa;
      img {
        width: 100%;
      }
    }
    .ja-description {
      font-size: 0.95em;
      color: #c85554;
    }
  }
}
</style>
