<template>
  <div class="create-user-finished">
    <h2>新しいアカウントが作成されました。</h2>
    <div class="line-block">
      <div class="title">名前</div>
      <div class="value name">{{ result.user_name }}</div>
    </div>
    <div class="line-block">
      <div class="title">ログインID</div>
      <div class="value">{{ result.user_account_name }}</div>
    </div>
    <div class="line-block">
      <div class="title">メールアドレス</div>
      <div class="value">{{ result.user_email }}</div>
    </div>
    <div class="line-block">
      <div class="title">デバイスID</div>
      <div class="value">{{ result.phone_device_id }}</div>
    </div>
    <div class="line-block" v-if="result.narrative_phone_id">
      <div class="title">ナラティブフォンID</div>
      <div class="value">{{ result.narrative_phone_id }}</div>
    </div>
    <div class="connect-to-zoom-column" v-if="needToConnectZoom">
      <h2>Zoomとのアカウント接続</h2>
      <div class="description">
        このアカウントは、まだZoomとの接続が行われていません。
        通話に際してはZoomアカウントとの接続が必要です。
        下のボタンを押して、接続したいZoomアカウントにログインし、ナラティブフォンアプリを承認してください。
      </div>
      <div class="controll">
        <button class="connect-to-zoom" @click="connectToZoom">Zoomに接続する</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as PHONE_DEIVCE_CONSTANTS from '@/assets/constants/phoneDeviceConstants.js'

export default{
  name: 'CreateUserFinished',

  props: {
    result: Object
  },

  computed: {
    needToConnectZoom () {
      if (this.result.phone_device_push_target == PHONE_DEIVCE_CONSTANTS.PUSH_SERVER_APNS) {
        if(this.result.ios_zoom_availabele) {
          return false
        }
      } else if (this.result.phone_device_push_target == PHONE_DEIVCE_CONSTANTS.PUSH_SERVER_FIREBASE) {
        if (this.result.android_zoom_available) {
          return false
        }
      }
      return true
    }
  },

  methods: {
    connectToZoom () {
      var zoomAuthURL = location.protocol 
          + '//' 
          + location.host 
          + '/api/user/contact_zoom_auth'
      console.log('zoom auth url: ' + zoomAuthURL)
      window.location.href = zoomAuthURL
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/colorTable.scss';
.create-user-finished {
  margin: 0 20px;
  border: 1px solid #ddd;
  padding: 0 20px;
  h2 {
    margin: 10px 0;
    font-size: 20px;
    color: #666;
    text-align: center;
  }
  .line-block {
    margin: 5px 0;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
    div {
      display: inline-block;
      &.title {
        color: #333;
        font-size: 14px;
      }
      &.value {
        margin-left: 10px;
        font-weight: bold;
        &.name {
          font-size: 20px;
        }
      }
    }
  }
  .connect-to-zoom-column {
    margin: 10px;
    padding: 10px 20px;
    border: 1px solid $danger-color;
    h2 {
      font-size: 20px;
      color: #666;
    }
    .description {
      line-height: 1.3rem;
    }
    .controll {
      padding: 10px;
      text-align: center;
      button.connect-to-zoom {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: $zoom-main-color;
        font-weight: bold;
        color: #fff;
      }
    }
  }
}
</style>