<template>
  <div class="app-download-view">
    <div class="contents" v-if="displayLang !== 'en'">
      <h1>アプリケーションダウンロード<a href="/app_download/en" class="switch-lang">English</a></h1>
      <div class="information">
        ナラティブフォンアプリは、iOS版とAndroid版の2つがあります。<br/>
        現在はベータ版のみが公開中です。<br/>
        ベータ版インストールには、事前にZoomアカウントの登録が必要となります。<br/>
        <a href="/contact">コンタクトページ</a>から登録の申し込みをお願いします。
      </div>
      <h2>iOS ベータ版</h2>
      <div class="description">
        iOS向けのベータ版は下記URLよりダウンロードいただけます。<br/>
        <a href="https://testflight.apple.com/join/VrtvE160">TestFlightにてインストールください。</a>
      </div>
      <h2>Android ベータ版</h2>
      <div class="description">
        Android向けのベータ版のAPKファイルは、下記URLよりダウンロードいただけます。<br/>
        ダウンロードの上、インストールしてお試しください。
        <a href="/data/narrativephone.20240630.prod.apk" target="_blank">
          ナラティブフォンAPKファイル(20240630production)
        </a>
      </div>
    </div>
    <div class="contents" v-if="displayLang === 'en'">
      <h1>Application download<a href="/app_download/jp" class="switch-lang">日本語</a></h1>
      <div class="information">
        There are two versions of the Narrative Phone app: iOS and Android.<br/>
        Currently only the beta version is available.
      </div>
      <h2>iOS beta version</h2>
      <div class="description">
        The beta version for iOS can be downloaded from the URL below.<br/>
        <a href="https://testflight.apple.com/join/VrtvE160">Please install with TestFlight.</a>
      </div>
      <h2>Android beta version</h2>
      <div class="description">
        You can download the beta APK file for Android from the URL below.<br/>
        Please download, install and try it out.<br/>
        <a href="/data/narrativephone.20240630.prod.apk" target="_blank">
          NarrativePhone.apk
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppDownloadView',

  props: {
    lang: String
  },

  data () {
    return {
      displayLang: 'jp'
    }
  },

  watch: {
    '$attrs': { handler: 'initLang', immediate: true }
  },

  methods: {
    initLang () {
      if (this.$route.params.lang[0]) {
        this.displayLang = this.$route.params.lang[0]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$breakpoints: (
  "phone": "screen and (max-width: 420px)",
  "tablet": "screen and (max-width: 1023px)",
  "pc": "screen and (min-width: 1024px)"
);

// メディアクエリ
@mixin mediaQuery($breakpoint: sp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

.app-download-view {
  background-color: #eee;
  .contents {
    min-height: calc(100% - 50px - 40px - 132px);
    padding: 20px 60px;
    background-color: #fff;
    @include mediaQuery('phone') {
      padding: 10px 20px;
    }
    h1 {
      margin: 0;
      margin-bottom: 20px;
      padding: 0;
      font-size: 1.4em;
      font-weight: bold;
      .switch-lang {
        padding-left: 20px;
        font-size: 12px;
        font-weight: normal;
      }
    }
    h2 {
      margin: 0;
      margin-top: 20px;
      font-size: 1.1em;
      font-weight: bold;
    }
    h3 {
      margin: 0;
      margin-top: 10px;
      font-size: 1em;
      font-weight: bold;
    }
    p {
      margin: 5px 0;
    }
    p.right-align {
      text-align: right;
    }
    ol {
      margin: 10px 0;
      padding-inline-start: 20px;
      @include mediaQuery('phone') {
        padding-inline-start: 10px;
      }
      li {
        margin: 8px;
      }
    }
    table {
      margin: 10px 0;
      width: 100%;
      border: 1px solid #999;
      border-collapse: collapse;
      &.handling {
        margin-left: 40px;
        width: calc(100% - 40px);
        @include mediaQuery('phone') {
          margin-left: 20px;
          width: calc(100% - 20px);
        }
      }
      th {
        border: 1px solid #999;
        padding: 10px;
        background-color: #ddd;
        text-align: left;
      }
      td {
        border: 1px solid #999;
        padding: 10px;
      }
      .index {
        width: 30%;
      }
    }
    .main-contents.en {
      word-break: normal;
    }
  }
}
</style>