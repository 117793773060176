import axios from './AxiosInstance'

export default {
  signin: ({ loginID, password }) => {
    var formData = new FormData()
    formData.append('login_id', loginID)
    formData.append('password', password)
    return new Promise((resolve, reject) => {
      axios.currentCsrfToken().then(token => {
        axios.httpClient({
          method: 'post',
          url: '/api/authenticate/system_admin_signin',
          headers: {
            'X-HTTP-Method-Override': 'POST',
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': token
          },
          withCredentials: true,
          data: formData
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },
}