<template>
  <div class="user-select-account-view">
    <div class="header">
      <img class="title-icon" src="/images/hero-logotype.png" />
      <img class="method-icon" src="/images/create_with_nb.png" />
      <h1>ナラティブブックアカウントから作成</h1>
    </div>
    <div class="account-list" v-if="!targetAccount && !userCreateResult">
      <div class="account-block" v-for="(account, index) in accounts" :key="'ac'+index">
        <div class="line-item"><span class="title">名前 : </span><span class="value name">{{ account.name }}</span></div>
        <div class="line-item"><span class="title">NBID : </span><span class="value">{{ account.nbid }}</span></div>
        <div class="line-item"><span class="title">メール : </span><span class="value">{{ account.email }}</span></div>
        <div class="line-item"><span class="title">AuthorID : </span><span class="value">{{ account.author_id }}</span></div>
        <div class="line-item"><span class="title">種別 : </span><span class="value">{{ accountType(account) }}</span></div>
        <div class="line-item"><span class="title">状態 : </span><span class="value">{{ accountState(account) }}</span></div>
        <div class="controll" v-if="account.available"><button @click="selectAccount(account)">このアカウントを利用</button></div>
        <div class="message" v-if="!account.available">このアカウントは登録済みです。</div>
      </div>
    </div>
    <div class="account-input" v-if="targetAccount && !userCreateResult">
      <div class="line-item" v-if="targetAccount.nb_organization_id">
        <attribute-in-form
          :labelText="'名前'"
          :necessary="false"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="''">
          <span class="value">{{ targetAccount.name }}</span>
        </attribute-in-form>
      </div>
      <div class="line-item" v-if="targetAccount.nb_organization_id">
        <attribute-in-form
          :labelText="'表示名'"
          :necessary="true"
          :validationState="validateOrgName.state"
          :validationMessage="validateOrgName.message"
          :attributeNotify="''">
          <input type="text" v-model="newOrganizationName"/>
        </attribute-in-form>
      </div>
      <div class="line-item" v-if="targetAccount.nb_organization_id">
        <attribute-in-form
          :labelText="'ログインID'"
          :necessary="true"
          :validationState="validateOrgAccountName.state"
          :validationMessage="validateOrgAccountName.message"
          :attributeNotify="'半角英数字大文字小文字、記号!@._/-+()6文字以上,120文字以下'">
          <input type="text" v-model="newOrganizationAccountName"/>
        </attribute-in-form>
      </div>
      <div class="line-item">
        <attribute-in-form
          :labelText="'NBID'"
          :necessary="false"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="''">
          <span class="value">{{ targetAccount.nbid }}</span>
        </attribute-in-form>
      </div>
      <div class="line-item">
        <attribute-in-form
          :labelText="'メールアドレス'"
          :necessary="false"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="''">
          <span class="value">{{ targetAccount.email }}</span>
        </attribute-in-form>
      </div>
      <div class="line-item">
        <attribute-in-form
          :labelText="'ナラティブブックAuthorID'"
          :necessary="false"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="''">
          <span class="value">{{ targetAccount.author_id }}</span>
        </attribute-in-form>
      </div>
      <div class="line-item">
        <attribute-in-form
          :labelText="'種別'"
          :necessary="false"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="''">
          <span class="value">{{ accountType(targetAccount) }}</span>
        </attribute-in-form>
      </div>
      <div class="line-item">
        <attribute-in-form
          :labelText="'状態'"
          :necessary="false"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="''">
          <span class="value">{{ accountState(targetAccount) }}</span>
        </attribute-in-form>
      </div>
      <div class="line-item">
        <attribute-in-form
          :labelText="'詳細'"
          :necessary="false"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="''">
          <textarea v-model="userDescription"></textarea>
        </attribute-in-form>
      </div>
      <div class="line-item">
        <attribute-in-form
          :labelText="'アイコンイメージ'"
          :necessary="false"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="''">
          <input type="file" accept="image/*" ref="photoInput" @change="postPhotoChange" />
        </attribute-in-form>
      </div>
      <div class="controll">
        <button class="cancel" @click="deselectAccount">選択し直す</button>
        <button class="submit" @click="createUser">ユーザー登録</button>
      </div>
    </div>
    <div class="account-input">
      <div class="controll">
        <button class="cancel" @click="backToMehotdSelection">戻る</button>
      </div>
    </div>
    <create-user-finished
      v-if="userCreateResult"
      :result="userCreateResult"
    />
    <confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
    />
  </div>
</template>

<script>
import { User } from '@/api'

import ConfirmDialog from '@/components/common/ConfirmDialog.vue'
import ConfirmDialogControll from '@/mixin/ConfirmDialogControll.js'

import CreateUserFinished from '@/components/createUser/CreateUserFinished.vue'
import AttributeInForm from '@/components/common/AttributeInForm.vue'

import * as PHONE_DEIVCE_CONSTANTS from '@/assets/constants/phoneDeviceConstants.js'

export default{
  mixins: [ConfirmDialogControll],

  name: 'UserSelectAccountView',

  components: {
    CreateUserFinished,
    AttributeInForm,
    ConfirmDialog
  },

  data () {
    return {
      accounts: [],
      phoneDevice: {},
      targetAccount: null,
      userDescription: '',
      userIconImage: null,
      userCreateResult: null,
      newOrganizationName: '',
      newOrganizationAccountName: ''
    }
  },

  watch: {
    '$route': { handler: 'storeQuery', immediate: true }
  },

  computed: {
    validateOrgName () {
      if (this.targetAccount.nb_organization_id) {
        if (this.newOrganizationName.length > 0) {
          return { state: 'valid', valid: true, message: '' }
        } else {
          return { state: 'invalid', valid: false, message: '入力必須です' }
        }
      }
      return { state: 'none', valid: true, message: '' }
    },

    validateOrgAccountName () {
      if (this.targetAccount.nb_organization_id) {
        if (this.newOrganizationAccountName.match(/^([a-zA-Z0-9]|!|@|\.|_|\/|-|\+|\(|\)){6,120}$/gi)) {
          return { state: 'valid', valid: true, message: '' }
        } else {
          return { state: 'invalid', valid: false, message: '入力形式が正しくありません' }
        }
      }
      return { state: 'none', valid: true, message: '' }
    },

  },

  methods: {
    storeQuery () {
      if (this.$route.query.result_code) {
        User.account_result({ code: this.$route.query.result_code }).then(res => {
          this.accounts = res.data.availables.authors
          this.phoneDevice = res.data.availables.phone_device
          this.createToken = res.data.availables.create_token
        })
      }
      // if (this.$route.query.availables) {
      //   var availables = JSON.parse(this.$route.query.availables)
      //   this.accounts = availables.authors
      //   this.phoneDevice = availables.phone_device
      // }
    },

    accountType(account) {
      if (account.nb_organization_id) {
        return '施設代表'
      } else if (/mem.+/g.test(account.author_id)) {
        return '施設職員'
      } else {
        return '一般利用者'
      }
    },

    accountState(account) {
      if (account.narrative_phone_id) {
        return '連携済み'
      } else {
        return '未連携'
      }
    },

    backToMehotdSelection () {
      if (this.phoneDevice) {
        var path = '/user/new_user_with_device?phone_device_description=' 
          + this.phoneDevice.phone_device_description 
          + '&phone_device_token=' + this.phoneDevice.phone_device_token 
          + '&push_target=' + this.phoneDevice.push_target
          + '&second_try=YES'
        this.$router.push(path)
      } else {
        this.showNotifyDialog('接続情報の取得失敗', '接続情報が消失しています。デバイスの登録操作を継続できません。\n一度アプリを終了してから、次回は「インポート」の操作を行ってください。')
      }
    },

    selectAccount(account) {
      this.targetAccount = account
      this.newOrganizationName = account.name
      this.newOrganizationAccountName = ''
      this.userDescription = ''
    },

    deselectAccount () {
      this.targetAccount = null
    },

    postPhotoChange (event) {
      this.userIconImage = event.target.files[0]
    },

    createUser () {
      if (this.targetAccount) {
        var formData = new FormData()
        if (this.targetAccount.nb_organization_id) {
          formData.append('user[name]', this.newOrganizationName)
          formData.append('user[account_name]', this.newOrganizationAccountName)
        } else {
          formData.append('user[name]', this.targetAccount.name)
          formData.append('user[account_name]', this.targetAccount.nbid+this.targetAccount.author_id+this.targetAccount.nb_organization_id)
        }
        formData.append('user[description]', this.userDescription)
        formData.append('user[nb_author_id]', this.targetAccount.author_id)
        formData.append('user[email]', this.targetAccount.email)
        if (this.targetAccount.nb_organization_id) {
          formData.append('user[nb_organization_id]', this.targetAccount.nb_organization_id)
        }
        if (this.targetAccount.narrative_phone_id) {
          formData.append('user[narrative_phone_id]', this.targetAccount.narrative_phone_id)
        }
        formData.append('user[icon_img]', this.userIconImage)
        formData.append('phone_device[phone_device_description]', this.phoneDevice.phone_device_description)
        formData.append('phone_device[phone_device_token]', this.phoneDevice.phone_device_token)
        formData.append('phone_device[push_target]', this.phoneDevice.push_target)
        User.createWithDevice({ formData: formData }).then(res => {
          console.log(res)
          var responseData = {
            user_id: res.data.user.id,
            nbid: res.data.user.nb_id,
            user_name: res.data.user.name,
            user_account_name: res.data.user.account_name,
            user_email: res.data.user.email,
            user_author_id: res.data.user.nb_author_id,
            user_description: res.data.user.description,
            narrative_phone_id: res.data.user.narrative_phone_id,
            phone_device_id: res.data.phone_device.phone_device_id,
            phone_device_authenticate_token: res.data.phone_device.device_authenticate_token,
            phone_device_description: res.data.phone_device.phone_device_description,
            phone_device_push_target: res.data.phone_device.push_target,
            ios_zoom_user_id: res.data.user.ios_zoom_user_id,
            android_zoom_user_id: res.data.user.android_zoom_user_id
          }
          // if (this.$native) {
          //   this.$native.event('deviceCreatedEvent', responseData)
          // }
          if (this.$native) {
            this.$native.event('userWithDeviceCreatedEvent', responseData)
            if (responseData.phone_device_push_target == PHONE_DEIVCE_CONSTANTS.PUSH_SERVER_APNS) {
              if(
                responseData.ios_zoom_available
                // responseData.ios_zoom_user_id && responseData.ios_zoom_user_id.length > 0 &&
                // responseData.ios_zoom_authorization_code && responseData.ios_zoom_authorization_code.length > 0 &&
                // responseData.ios_zoom_access_token && responseData.ios_zoom_access_token.length > 0 &&
                // responseData.ios_zoom_refresh_token && responseData.ios_zoom_refresh_token.length > 0
              ) {
                this.$native.event('zoomUserActivatedEvent', responseData)
              }
            } else if (responseData.phone_device_push_target == PHONE_DEIVCE_CONSTANTS.PUSH_SERVER_FIREBASE) {
              if (
                responseData.android_zoom_available
                // responseData.android_zoom_user_id && responseData.android_zoom_user_id.length > 0 &&
                // responseData.android_zoom_authorization_code && responseData.android_zoom_authorization_code.length > 0 &&
                // responseData.android_zoom_access_token && responseData.android_zoom_access_token.length > 0 &&
                // responseData.android_zoom_refresh_token && responseData.android_zoom_refresh_token.length > 0
              ) {
                this.$native.event('zoomUserActivatedEvent', responseData)
              }
            }
          }
          this.userCreateResult = responseData
        }).catch(err => {
          console.error(err)
        })
      }
    }

  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/colorTable.scss';
.user-select-account-view {
  .header {
    padding: 20px;
    text-align: center;
    img {
      display: block;
      margin: 10px auto;
      &.title-icon {
        width: 390px;
        @include mediaQuery('phone') {
          width: calc(100% - 40px);
        }
      }
      &.method-icon {
        height: 40px;
      }
    }
    h1 {
      font-size: 21px;
      color: #666;
    }
  }
  .account-list {
    padding: 0 20px;
    .account-block {
      margin: 10px;
      padding: 10px;
      border: 1px solid #ddd;
      .line-item {
        margin: 5px 0;
        padding: 5px 0;
        border-bottom: 1px solid #ddd;
        span {
          display: inline-block;
          &.title {
            color: #333;
            font-size: 14px;
          }
          &.value {
            margin-left: 10px;
            font-weight: bold;
            &.name {
              font-size: 20px;
            }
          }
        }
      }
      .controll {
        text-align: right;
        button {
          padding: 5px 10px;
          border: none;
          border-radius: 5px;
          font-weight: bold;
          color: #fff;
          background-color: $phone-main-color;
        }
      }
      .message {
        text-align: center;
      }
    }
  }
  .account-input {
    margin: 0 20px;
    padding: 10px;
    border: 1px solid #ddd;
    .line-item {
      textarea {
        resize: none;
        width: 100%;
        height: 4rem;
      }
    }
    .controll {
      text-align: right;
      button {
        margin-left: 10px;
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        font-weight: bold;
        &.cancel {
          border: 1px solid #666;
          color: #666;
          background-color: #fff;
        }
        &.submit {
          background-color: $phone-main-color;
          color: #fff;
        }
      }
    }
  }
}
</style>