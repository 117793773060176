<template>
  <div class="user-manual-view">
    <div class="header">
      <img class="long-logo" src="/images/small_line_logo.png" />
      <img class="small-logo" src="/images/narrative_phone_logo.svg" />
    </div>
    <div class="contents">
      <div class="main-contents jp" v-if="displayLang === 'jp'">
        <h1>ナラティブフォン ユーザーマニュアル</h1>
        <div class="content-block">
          <h2>はじめに</h2>
          <div class="notify-block">
            ナラティブフォンは、ナラティブブックと連携することにより、ナラティブブックのアドレス帳に登録されている方とビデオ通話を行うことができる通話アプリです。<br/>
            利用のためには、ナラティブブックのアカウント登録と、ナラティブブックでのかかりつけ、家族設定が事前に必要です。<br/>
            アカウントの登録のためには、ナラティブブックアカウントのログイン情報が必要となります。事前に手元に用意しておいてください。<br/>
            また、ナラティブフォンでは、ビデオ通話にZoomを利用しています。通話の時間や同時通話の参加者数はナラティブフォント連携したZoomアカウントの制限に従います。<br/>
          </div>
        </div>
        <div class="content-block">
          <h2>ナラティブフォンの始め方</h2>
          <div class="subcontent-block">
            <h3>規約の同意</h3>
            ナラティブフォンを最初に起動した場合には、最初にナラティブフォンの利用規約同意画面が表示されます。内容を読んだ上で、同意するのであれば「規約へ同意」のチェックを入れて「次へ」ボタンを押してください。<br/>
            <img src="/images/agreement.jpeg" class="border" />
          </div>
          <div class="subcontent-block">
            <h3>アカウントの登録</h3>
            規約への同意画面の後、ナラティブフォンのアカウント作成画面に移動します。<br/>
            ナラティブフォンのアカウント作成には新規登録とインポートの二種類が存在します。<br/>
            初めて使う方は「ナラティブブックアカウントから作成」を、他のデバイスなどですでにアカウントを作成している方は「ナラティブブックアカウントからインポート」を選択してください。<br/>
            表記されている枠の内部をタップするとそれぞれの方法に進みます。<br/>
            <img src="/images/select_create_method.jpeg" class="border" />
          </div>
          <div class="subcontent-block">
            <h3>ナラティブブックアカウントから作成</h3>
            「ナラティブブックアカウントから作成」をタップすると、ナラティブブックのシングルサインオン画面に遷移します。お持ちのナラティブブックのNBID(ログインID)とパスワードでログインしてください。<br/>
            <img src="/images/nb_signin.png"/>
          </div>
          <div class="subcontent-block">
            <h3>利用可能なナラティブブックアカウントの選択</h3>
            ナラティブブックの認証が成功すると、登録可能なアカウントの一覧が表示されます。<br/>
            ナラティブブックを一般の利用者として利用している場合は、個人のアカウントが、職員のアカウントを持っている場合は、個人のアカウントと職員のアカウントが、職員で、かつ、施設管理者権限を持っている場合は施設の代表アカウントが登録できます。<br/>
            複数の施設に職員として登録している場合はそれぞれの施設のアカウントを作成できます。<br/>
            作成したいアカウントの「このアカウントで作成」ボタンをタップしてください。<br/>
            <h3>ナラティブフォンアカウントの情報入力</h3>
            作成したいアカウントを選択すると、ナラティブフォンで利用する情報の入力画面に遷移します。ここでは、ナラティブフォンで利用する表示名（通話相手に表示する名前）と詳細情報（通話相手に表示する補足情報）の2つを入力して、「作成」ボタンをタップし、アカウントとデバイスの登録を行います。<br/>
            <img src="/images/select_create_account.png" />
          </div>
          <div class="subcontent-block">
            <h3>Zoom連携の認証</h3>
            ナラティブフォンアカウントとデバイスの登録が成功すると、Zoom接続情報の登録に進みます。「Zoomに接続する」のボタンをタップしてください。<br/>
            Zoomのログイン画面が表示されるので、アカウントと連携したいZoomアカウント情報を入力してログインします。ログインに成功すると、ナラティブフォンとZoomアカウントの連携同意画面に遷移するので、「Allow」をタップして同意してください。<br/>
            Zoomとの連携が完了すると、アプリの画面に遷移します。<br/>
            <img src="/images/confirm_zoom.png" />
          </div>
          <div class="subcontent-block">
            <h3>ナラティブブックアカウントからインポート</h3>
            アカウント作成画面で「ナラティブブックアカウントからインポート」を選択した場合、新規作成と同様にナラティブブックのシングルサインオン画面に遷移します。お持ちのナラティブブックのNBID(ログインID)とパスワードでログインしてください。<br/>
          </div>
          <div class="subcontent-block">
            <h3>登録済みアカウントの選択</h3>
            ログインしたナラティブブックアカウントで作成済みのナラティブフォンアカウントが存在する場合、インポート可能なナラティブフォンアカウントの一覧が表示されます。インポートしたいアカウントの「アカウント情報を取り込み」ボタンをタップしてください。<br/>
            選択したアカウントに、すでにZoomアカウントが連携済みの場合は処理が完了してアプリの画面に移動します。Zoomアカウントが無効であったり、登録されていない場合には、Zoom連携の認証画面に移動するので、「Zoom連携の認証」の処理を行ってください。<br/>
            インポートの場合、過去に作成した「連絡先」の情報や「通話履歴」の情報も一緒にインポートされます。<br/>
            <img src="/images/select_import.png" />
          </div>
        </div>
        <div class="content-block">
          <h2>ユーザー</h2>
          ナラティブフォンでは、複数のアカウントを一つのデバイスに登録できます。連絡先とお気に入りはアカウントごとに切り分けられ、通話はアクティブなアカウントで発信します。<br/>
          着信は、登録しているすべてのアカウントで着信コールがなされ、応答すると着信したアカウントがアクティブになります。<br/>
          アクティブなアカウントは、アカウント切り替えボタンをタップすることで変更することができます。<br/>
          <img src="/images/switch_account.jpeg" class="border" />
        </div>
        <div class="content-block">
          <h2>連絡先</h2>
          ナラティブフォンでは、ナラティブブックのアドレス帳に登録されている家族やかかりつけ施設、職員の場合は同一施設の職員とかかりつけ利用者の連絡先をインポートすることで連絡先を登録します。<br/>
          <img src="/images/addressbook.jpeg" class="border" />
          <div class="subcontent-block">
            <h3>連絡先のインポート</h3>
            連絡先はグループ分けして分類できます。連絡先グループのインポートボタンをタップすると、連絡先インポート画面に移動します。<br/>
            連絡先インポート画面では、連携しているナラティブブックアカウントのアドレス帳の中から、ナラティブフォンアカウントと連携済みの連絡先が表示されます。<br/>
            「ナラティブフォン連携済みのみを表示」のチェックを外すと、ナラティブブックのアドレス帳にある全員のリストが表示されます。<br/>
            インポート可能なアカウントをタップするとアカウントの横にチェックが付くので、インポートしたいアカウントを選択して、「インポート」ボタンをタップしてください。<br/>
            <img src="/images/import_address_noncheck.jpeg" class="border"/>
          </div>
          <div class="subcontent-block">
            <h3>連絡先の詳細表示</h3>
            連絡先のエントリをタップすると、連絡先詳細の画面が表示されます。<br/>
            連絡先詳細の画面では、お気に入りへの登録/解除、メモの登録、連絡先グループの移動、通話の開始が行なえます。<br/>
            お気に入りへの登録とメモの登録は画面右上の「変更」ボタンを押すことで確定します。<br/>
            <img src="/images/adress_entry_description.jpeg" class="border" />
          </div>
          <div class="subcontent-block">
            <h3>連絡先グループの追加と編集</h3>
            連絡先グループは、使い始めのときにははじめから「一般」グループが追加されています。<br/>
            連絡先画面の右上にある「グループ追加」のボタンで新しい連絡先グループを追加できます。連絡先グループの編集アイコンをタップすることで、グループ名称を変更できます。<br/>
            <img src="/images/edit_address_group.jpeg" class="border" />
          </div>
        </div>
        <div class="content-block">
          <h2>お気に入り</h2>
          連絡先の詳細画面でお気に入りに追加することで、連絡先をお気に入りに入れることができます。<br/>
          お気に入りに入れた連絡先は、お気に入りのリストから直接通話準備画面に移動できます。<br/>
          <img src="/images/favo.jpeg" class="border" />
        </div>
        <div class="content-block">
          <h2>履歴</h2>
          通話履歴では、登録されているすべてのアカウントでの通話履歴が表示されます。<br/>
          通話履歴のブロックの最上部には発信もしくは着信したアカウント名が表示され、矢印で発信か着信かを識別できます。また、不在着信の場合には赤い文字で表示されます。<br/>
          次の行で、発信者が一行目、2行目以降に通話参加者のリストが表示されます。<br/>
          コールバックボタンをタップすると、その通話履歴の参加者全員を通話先に設定した状態で通話準備画面に移動します。<br/>
          <img src="/images/history_full.jpeg" class="border" />
        </div>
        <div class="content-block">
          <h2>設定</h2>
          設定画面では、ナラティブフォンに登録されているユーザーアカウントのリストが表示され、詳細な情報が表示されます。<br/>
          config
          <div class="subcontent-block">
            <h3>アカウントの追加</h3>
            設定画面の右上の「アカウント追加」をタップすると、アカウント追加画面に遷移します。<br/>
            初回登録時の要領で、アカウントの追加とインポートが行えます。<br/>
            <img src="/images/config.jpeg" class="border" />
          </div>
          <div class="subcontent-block">
            <h3>アカウントの削除と編集</h3>
            非アクティブなアカウントの横には削除ボタンが、アクティブなアカウントの横には編集ボタンが表示されます。<br/>
            削除ボタンをタップすると、デバイスからユーザー情報が削除されます。<br/>
            また、アクティブなアカウントの横には編集ボタンがあり、このボタンをタップすることで登録しているユーザー情報を変更できます。<br/>
            ここで変更された「表示」と「説明」は相手の連絡先に反映されます。<br/>
            また、アクティブなアカウントの場合、「Zoomに再接続」が表示されており、連携されているZoomアカウントの情報を更新したり、変更したりすることができます。<br/>
          </div>
          <div class="subcontent-block">
            <h3>高度な設定</h3>
            画面右上の「高度な設定」をタップすると、webコンソールが表示されます。<br/>
            webコンソールでログインして、ナラティブフォンアカウントを選択すると、アカウントに関する詳細情報や連携しているナラティブブックアカウントの情報、アカウントを登録しているデバイスのリスト、連携しているZoomアカウントの情報、連絡先情報が表示されます。<br/>
            また、webコンソールでは以下の操作を行うことができます。<br/>
            web_console_signin
            <div class="subcontent-block">
              <h4>ユーザー情報の編集</h4>
              ユーザーの登録情報を編集できます。<br/>
            </div>
            <div class="subcontent-block">
              <h4>ユーザーの削除</h4>
              ユーザーの情報をサーバーから永久に削除します。<br/>
            </div>
            <div class="subcontent-block">
              <h4>デバイスの削除</h4>
              ユーザーからデバイスを削除して、デバイスへの呼び出し、通話を停止させます。<br/>
            </div>
            <div class="subcontent-block">
              <h4>Zoomへの再接続</h4>
              一つのアカウントには、iOSアプリ用のZoomアカウント連携とAndroidアプリ用のZoomアカウント連携の2つが登録できます。それぞれに対して、Zoomアカウントの再接続を行うことで、連携させるZoomアカウントの更新や変更を行えます。<br/>
            </div>
            <div class="subcontent-block">
              <h4>Zoomとの連携解除</h4>
              登録されているZoomアカウントの連携を解除し、ナラティブフォンのサーバーからZoomの情報を永久に削除します。<br/>
            </div>
            <div class="subcontent-block">
              <h4>連絡先</h4>
              連絡先の削除、グループ移動が行なえます。<br/>
            </div>
          </div>
        </div>
        <div class="content-block">
          <h2>通話</h2>
          <div class="subcontent-block">
            <h3>通話の発信</h3>
            連絡先画面から通話相手の詳細画面を表示して通話ボタンを押すか、お気に入りの連絡先リストの項目を押すと、通話準備画面に移動します。<br/>
            通話準備画面では、通話先を追加することができます。<br/>
            <img src="/images/call_process.png" />
          </div>
          <div class="subcontent-block">
            <h3>複数の通話先を一度に呼び出す</h3>
            一度に複数の相手と通話したいときには、通話準備画面の「連絡先追加」をタップします。<br/>
            連絡先リストが表示されるので、追加したい相手の行をタップしてください。<br/>
            更に追加したい相手がいる場合には、同じことを繰り返すことで、通話相手を追加できます。<br/>
            <img src="/images/add_attendee.png"/>
          </div>
          <div class="subcontent-block">
            <h3>発信</h3>
            通話準備画面の通話開始ボタンをタップすることで、選択した通話先を呼び出します。<br/>
            呼び出し中は呼び出し画面が表示されます。<br/>
            相手側が話中の場合、「話し中のため通話できません」のダイアログが表示され、呼び出しが中止されます。<br/>
            ※複数の通話先を選択している場合は、通話先の全員が話中の場合は呼び出しが中止されますが、一人でも通話可能であれば、呼び出しが継続されます。<br/>
          </div>
          <div class="subcontent-block">
            <h3>着信</h3>
            自分に対して呼び出しがあった場合、ナラティブフォンのコールが鳴ります。呼び出しダイアログが表示されるので、着信と着信拒否いずれかをタップしてください。<br/>
            デバイスがロック状態の場合は、ボタンのスワイプで応答、ホームボタンの二度押しで着信拒否ができます。<br/>
            <img src="/images/receive.png" />
          </div>
          <div class="subcontent-block">
            <h3>着信の拒否</h3>
            呼び出しのダイアログで着信拒否ボタンをタップするか、デバイスロック状態の呼び出し画面でホームボタンをに通しすると着信を拒否できます。<br/>
            着信を拒否した場合、呼び出しは停止し、発信側に着信が拒否されたことが伝えられます。<br/>
            発信側は、呼び出している全員が着信を拒否した場合に「着信が拒否されました」のダイアログが表示され、呼び出しを終了します。<br/>
          </div>
          <div class="subcontent-block">
            <h3>着信通話</h3>
            呼び出しダイアログで着信ボタンをタップするか、デバイスロック状態で応答をスワイプすると通話を開始します。<br/>
            通話画面は自分以外の通話参加者4名までが分割画面に表示され、それを超える参加者は、小さな画面で画面下に表示されます。画面下の小さな画面をタップすると、大きな画面分割の画面に表示されるように鳴ります。<br/>
          </div>
          <div class="subcontent-block">
            <h3>通話参加者の途中追加（発信側のみの操作）</h3>
            発信側の通話画面には「参加者追加ボタン」が表示されます。このボタンをタップすると、連絡先画面が表示され、追加したい参加者を選択できます。<br/>
            参加者を追加すると、追加された参加者には呼び出しのコールが送られ、応答すると通話に参加します。<br/>
            <img src="/images/add_attendee_in_calling.png" />
          </div>
          <div class="subcontent-block">
            <h3>マイクのミュートとカメラのON/OFF</h3>
            通話画面の下部にあるマイクアイコンとカメラアイコンをタップすることで、マイクのミュートとカメラのON/OFFが切り替えられます。
          </div>
          <div class="subcontent-block">
            <h3>通話の終了</h3>
            通話画面の下部にある通話終了ボタンをタップすると、通話を終了できます。<br/>
            着信側での通話終了は、操作したユーザーが通話から抜けます。<br/>
            発信側での通話終了は、全員に対して通話終了します。<br/>
            着信側の全員が通話終了して通話から抜けた場合、発信側でも通話を終了して元の画面に戻ります。<br/>
          </div>
        </div>
        <div class="content-block">
          <h2>ユーザーの削除</h2>
          ナラティブフォンのアカウントは、アプリ上に登録されている情報のほか、通話を仲介するためのサーバーにアカウント情報が登録されています。<br/>
          アカウントの情報を完全に削除するためには、アプリの削除だけではなく、「高度な設定」で利用情報を削除する必要があります。
          <div class="subcontent-block">
            <h3>アプリケーションの削除</h3>
            アプリの削除は、通常通りアプリアイコンを長押しして、「アプリの削除」を選択することでアプリの削除が行なえます。<br/>
            アプリを削除した時点で、デバイス内に存在する登録情報はすべて削除されます。
          </div>
          <div class="subcontent-block">
            <h3>サーバーからのアカウント削除</h3>
            サーバーからアカウントの情報を削除するためには、「高度な設定」のwebコンソールを開いて、「ユーザーの削除」を行う必要があります。<br/>
            <a href="https://phone.narrativebook.jp/user_config" target="_blank">webコンソール（https://phone.narrativebook.jp/user_config）</a>にアクセスしてログインを行い、「ユーザーの削除」をタップしてください。<br/>
            ユーザー削除後はコンソールから自動的にログアウトされ、サーバーに登録されているZoom関連情報、ナラティブブック関連情報は永久に削除されます。<br/>
            <img src="/images/web_config_user_delete.png" />
          </div>
        </div>
      </div>
      <div class="main-contents en" v-if="displayLang === 'en'">
        <h1>Narrative Phone User Manual</h1>
        <div class="content-block">
          <h2>Introduction</h2>
          <div class="notify-block">
            Narrative Phone is a calling app that allows you to make video calls with people registered in Narrative Book's address book by linking with Narrative Book.<br/>
            In order to use the service, you must register for a Narrative Book account and set up a primary care provider and family in Narrative Book in advance.<br/>
            To register an account, you will need your Narrative Book account login information. Please have them on hand in advance.<br/>
            Narrative Phone also uses Zoom for video calls. The duration of the call and the number of participants in the call at the same time will be subject to the limits of the Zoom account linked to the narrative phone.<br/>
          </div>
        </div>
        <div class="content-block">
          <h2>How to get started with Narrative Phone</h2>
          <div class="subcontent-block">
            <h3>Acceptance of terms and conditions</h3>
            When you first launch Narrative Phone, the Narrative Phone Terms of Use agreement screen will be displayed. After reading the contents, if you agree, please check "I agree to the terms and conditions(規約へ同意)" and press the "Next(次へ)" button.<br/>
            <img src="/images/agreement.jpeg" class="border" />
          </div>
          <div class="subcontent-block">
            <h3>Register an account</h3>
            After agreeing to the terms, you will be redirected to the Narrative Phone account creation screen.<br/>
            There are two types of Narrative Phone account creation: new registration and import.<br/>
            If you are using it for the first time, please select "Create from a Narrative Book Account(ナラティブブックアカウントから作成)", and if you have already created an account on another device, please select "Import from a Narrative Book Account(ナラティブブックアカウントからインポート)".<br/>
            Tap inside the indicated frame to proceed to each method.<br/>
            <img src="/images/select_create_method.jpeg" class="border" />
          </div>
          <div class="subcontent-block">
            <h3>Create from your Narrative Book account</h3>
            When you tap "Create from Narrative Book Account", you will be redirected to the Narrative Book single sign-on screen. Please log in using your narrative book's NBID (login ID) and password.<br/>
            <img src="/images/nb_signin.png"/>
          </div>
          <div class="subcontent-block">
            <h3>Selection of available narrative book accounts</h3>
            Once the narrative book has been successfully authenticated, a list of available accounts will be displayed.<br/>
            If you are using Narrative Book as a general user, your personal account will be the same, and if you have an employee account, your personal account and the employee's account will be the same if you are an employee and have facility administrator privileges. If so, you can register a representative account for the facility.<br/>
            If you are registered as an employee at multiple facilities, you can create an account for each facility.<br/>
            Tap the "Create with this account(このアカウントで作成)" button for the account you want to create.<br/>
            <h3>Enter information for your Narrative Phone account</h3>
            When you select the account you want to create, you will be redirected to the information entry screen for use with Narrative Phone. Here, enter the display name (the name to be displayed to the other party) and detailed information (supplementary information to be displayed to the other party) to be used on the Narrative Phone, tap the "Create(作成)" button, and create an account and device. Register.<br/>
            <img src="/images/select_create_account.png" />
          </div>
          <div class="subcontent-block">
            <h3>Authentication of Zoom cooperation</h3>
            Once you have successfully registered your Narrative Phone account and device, you will proceed to register your Zoom connection information. Tap the "Connect to Zoom" button.<br/>
            The Zoom login screen will be displayed, so enter the Zoom account information you want to link with your account and log in. If you successfully log in, you will be redirected to the consent screen for linking your Narrative Phone and Zoom account. Tap "Allow" to consent.<br/>
            Once the link with Zoom is complete, you will be redirected to the app screen.<br/>
            <img src="/images/confirm_zoom.png" />
          </div>
          <div class="subcontent-block">
            <h3>Import from your Narrative Book account</h3>
            If you select "Import from Narrative Book account" on the account creation screen, you will be redirected to the Narrative Book single sign-on screen, just like when creating a new account. Please log in using your narrative book's NBID (login ID) and password.<br/>
          </div>
          <div class="subcontent-block">
            <h3>Select registered account</h3>
            If a narrative phone account has already been created with the logged-in narrative book account, a list of narrative phone accounts that can be imported will be displayed. Tap the "Import account information" button for the account you want to import.<br/>
            If a Zoom account has already been linked to the selected account, the process will be completed and you will be redirected to the app screen. If your Zoom account is invalid or not registered, you will be redirected to the Zoom integration authentication screen, so please complete the "Zoom integration authentication" process.<br/>
            When importing, previously created "Contact" information and "Call History" information will also be imported.<br/>
            <img src="/images/select_import.png" />
          </div>
        </div>
        <div class="content-block">
          <h2>User screen</h2>
          Narrative Phone allows you to register multiple accounts on one device. Contacts and favorites are separated by account, and calls are made with the active account.<br/>
          Incoming calls are made to all registered accounts, and when you answer, the account that received the call becomes active.<br/>
          You can change the active account by tapping the account switch button.<br/>
          <img src="/images/switch_account.jpeg" class="border" />
        </div>
        <div class="content-block">
          <h2>Contacts screen</h2>
          With Narrative Phone, in the case of family members, family facilities, and staff registered in the address book of Narrative Book, contacts can be registered by importing the contact information of staff of the same facility and family users.<br/>
          <img src="/images/addressbook.jpeg" class="border" />
          <div class="subcontent-block">
            <h3>Import contacts</h3>
            Contacts can be categorized into groups. Tap the import contact group button to move to the contact import screen.<br/>
            On the contact import screen, contacts that have been linked to the Narrative Phone account will be displayed from the address book of the linked Narrative Book account.<br/>
            If you uncheck "Show only those connected to Narrative Phone", a list of everyone in your Narrative Book address book will be displayed.<br/>
            When you tap an account that can be imported, a check will be placed next to the account, so select the account you want to import and tap the "Import" button.<br/>
            <img src="/images/import_address_noncheck.jpeg" class="border"/>
          </div>
          <div class="subcontent-block">
            <h3>View contact details</h3>
            Tap a contact entry to display the contact details screen.<br/>
            On the contact details screen, you can add/cancel a contact to favorites, add a memo, move contact groups, and start a call.<br/>
            To register an item as a favorite or add a memo, click the "Change" button at the top right of the screen.<br/>
            <img src="/images/adress_entry_description.jpeg" class="border" />
          </div>
          <div class="subcontent-block">
            <h3>Add and edit contact groups</h3>
            When you first start using the contact group, the "General(一般)" group is already added.<br/>
            You can add a new contact group by clicking the "Add group(グループ追加)" button at the top right of the contact screen. You can change the group name by tapping the edit icon for a contact group.<br/>
            <img src="/images/edit_address_group.jpeg" class="border" />
          </div>
        </div>
        <div class="content-block">
          <h2>favorite screen</h2>
          You can add a contact to your favorites by adding it to favorites on the contact details screen.<br/>
          Once you've marked your favorite contacts, you can move them directly from your favorites list to the call preparation screen.<br/>
          <img src="/images/favo.jpeg" class="border" />
        </div>
        <div class="content-block">
          <h2>History screen</h2>
          Call history displays call history from all registered accounts.<br/>
          The name of the account that made or received the call is displayed at the top of the call history block, and you can use the arrow to identify whether the call was made or received. Also, missed calls will be displayed in red.<br/>
          In the following lines, the caller will appear in the first line, and the list of call participants will appear in the second and subsequent lines.<br/>
          When you tap the callback button, you will move to the call preparation screen with all participants in that call history set as call destinations.<br/>
          <img src="/images/history_full.jpeg" class="border" />
        </div>
        <div class="content-block">
          <h2>Settings screen</h2>
          The settings screen displays a list of user accounts registered on the Narrative Phone and displays detailed information.<br/>
          <div class="subcontent-block">
            <h3>Add account</h3>
            Tap "Add account" at the top right of the settings screen to move to the account addition screen.<br/>
            You can add and import accounts in the same way as when registering for the first time.<br/>
            <img src="/images/config.jpeg" class="border" />
          </div>
          <div class="subcontent-block">
            <h3>Delete and edit your account</h3>
            Inactive accounts will have a delete button next to them, and active accounts will have an edit button next to them.<br/>
            Tapping the delete button will remove the user information from the device.<br/>
            There is also an edit button next to the active account, and you can change the registered user information by tapping this button.<br/>
            The "display" and "description" changed here will be reflected in the other party's contact information.<br/>
            In addition, if the account is active, "Reconnect to Zoom" will be displayed, allowing you to update or change the information of the linked Zoom account.<br/>
          </div>
          <div class="subcontent-block">
            <h3>Advanced settings</h3>
            Tap "Advanced Settings(高度な設定)" at the top right of the screen to display the web console.<br/>
            When you log in on the web console and select a Narrative Phone account, you can see detailed information about the account, information on linked Narrative Book accounts, a list of devices that have registered the account, information on linked Zoom accounts, and contact information. Information will be displayed.<br/>
            Additionally, you can perform the following operations using the web console.<br/>
            <div class="subcontent-block">
              <h4>Edit user information</h4>
              You can edit the user's registration information.<br/>
            </div>
            <div class="subcontent-block">
              <h4>Delete user</h4>
              Permanently delete your information from our servers.<br/>
            </div>
            <div class="subcontent-block">
              <h4>Delete device</h4>
              Removes the device from the user to stop calls and calls to the device.<br/>
            </div>
            <div class="subcontent-block">
              <h4>Reconnect to Zoom</h4>
              Two accounts can be registered in one account: a Zoom account link for the iOS app and a Zoom account link for the Android app. By reconnecting the Zoom account for each, you can update or change the linked Zoom account.<br/>
            </div>
            <div class="subcontent-block">
              <h4>Cancellation of cooperation with Zoom</h4>
              The registered Zoom account will be unlinked and the Zoom information will be permanently deleted from the Narrative Phone server.<br/>
            </div>
            <div class="subcontent-block">
              <h4>contact address</h4>
              You can delete contacts and move groups.<br/>
            </div>
          </div>
        </div>
        <div class="content-block">
          <h2>call</h2>
          <div class="subcontent-block">
            <h3>Making a call</h3>
            If you display the details of the person you are calling from the Contacts screen and press the call button, or press an item in your favorite contacts list, you will be taken to the call preparation screen.<br/>
            On the call preparation screen, you can add call destinations.<br/>
            <img src="/images/call_process.png" />
          </div>
          <div class="subcontent-block">
            <h3>Call multiple destinations at once</h3><br/>
            If you want to talk to multiple people at once, tap "Add Contact(連絡先追加)" on the call preparation screen.<br/>
            A contact list will be displayed. Tap the row of the person you want to add.<br/>
            If you want to add more people, you can add more people by repeating the same steps.<br/>
            <img src="/images/add_attendee.png"/>
          </div>
          <div class="subcontent-block">
            <h3>Outgoing</h3>
            Tap the call start button on the call preparation screen to call the selected call destination.<br/>
            The calling screen is displayed while the call is in progress.<br/>
            If the other party is busy, a dialog box saying "You cannot call because the other party is busy" will be displayed and the call will be canceled.<br/>
            *If multiple call destinations are selected, the call will be canceled if all the call destinations are busy, but the call will continue if at least one person is available.<br/>
          </div>
          <div class="subcontent-block">
            <h3>Incoming call</h3>
            If you receive a call, your narrative phone will ring. A call dialog will be displayed, so tap either to receive the call or to reject the call.<br/>
            When your device is locked, you can answer a call with a swipe of the button, or reject a call with a double press of the home button.<br/>
            <img src="/images/receive.png" />
          </div>
          <div class="subcontent-block">
            <h3>Rejecting a call</h3>
            You can reject an incoming call by tapping the Reject call button in the call dialog or by pressing the Home button on the call screen when the device is locked.<br/>
            If you decline a call, the calls stop and the caller is notified that the call was declined.<br/>
            If all callers reject the call, the calling party will see a dialog saying "Call Rejected" and end the call.<br/>
          </div>
          <div class="subcontent-block">
            <h3>Make an incoming call</h3>
            Start a call by tapping the incoming call button in the call dialog or by swiping to answer when the device is locked.<br/>
            On the call screen, up to 4 call participants other than yourself will be displayed on a split screen, and additional participants will be displayed on a small screen at the bottom of the screen. Tap the small screen at the bottom of the screen and it will sound as if it were displayed on a large split screen screen.<br/>
          </div>
          <div class="subcontent-block">
            <h3>Adding call participants midway (operation by calling party only)</h3>
            The "Add participant button" will be displayed on the call screen of the calling party. Tapping this button will take you to the Contacts screen where you can select the participants you want to add.<br/>
            When you add a participant, the added participant receives a call to ring and joins the call when they answer.<br/>
            <img src="/images/add_attendee_in_calling.png" />
          </div>
          <div class="subcontent-block">
            <h3>Mute the microphone and turn the camera on/off</h3>
            By tapping the microphone icon and camera icon at the bottom of the call screen, you can mute the microphone and turn the camera on/off.
          </div>
          <div class="subcontent-block">
            <h3>End the call</h3>
            You can end the call by tapping the end call button at the bottom of the call screen.<br/>
            When the called party ends the call, the user who operated the call exits the call.<br/>
            When the caller ends the call, the call ends for everyone.<br/>
            If all called parties end the call and exit the call, the calling party also ends the call and returns to the original screen.<br/>
          </div>
        </div>
        <div class="content-block">
          <h2>Delete user</h2>
          Narrative Phone accounts have information registered on the app as well as account information registered on the server that mediates calls.<br/>
          In order to completely delete your account information, you must not only delete the app, but also delete usage information in "Advanced Settings.[web cosole]"
          <div class="subcontent-block">
            <h3>Delete application</h3>
            To delete an app, press and hold the app icon as usual and select "Delete app".<br/>
            When you delete the app, all registration information that exists on your device will be deleted.
          </div>
          <div class="subcontent-block">
            <h3>Delete account from server</h3>
            To delete account information from the server, you need to open the web console in "Advanced Settings" and select "Delete User."<br/>
            Please access <a href="https://phone.narrativebook.jp/user_config" target="_blank">the web console (https://phone.narrativebook.jp/user_config)</a>, log in, and tap "Delete user".<br/>
            After the user is deleted, you will be automatically logged out from the console, and Zoom-related information and Narrative Book-related information registered on the server will be permanently deleted.<br/>
            <img src="/images/web_config_user_delete.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserManualView',

  data () {
    return {
      displayLang: 'jp'
    }
  },

  watch: {
    '$attrs': { handler: 'initLang', immediate: true }
  },

  methods: {
    initLang () {
      if (this.$route.params.lang) {
        this.displayLang = this.$route.params.lang[0]
      }
      // if (this.lang[0] === 'en') {
      //   this.displayLang = 'en'
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/colorTable.scss';
.user-manual-view {
  .header {
    display: flex;
    justify-content: space-between;
    padding: 3px 5px;
    border-bottom: 1px solid #ddd;
    .long-logo {
      height: 30px;
    }
    .small-logo {
      display: none;
      height: 30px;
    }
    @include mediaQuery('phone') {
      .long-logo {
        display: none;
      }
      .small-logo {
        display: inline;
      }
    }
  }
  .contents {
    padding: 10px 20px;
    .main-contents {
      .content-block {
        padding: 10px;
        border: 1px solid #ddd;
      }
      h1 {
        margin: 10px 0;
        text-align: center;
        font-size: 1.3rem;
        color: #666;
      }
      h2 {
        margin: 10px 0;
        font-size: 1.2rem;
        color: #666;
      }
      img {
        margin-top: 10px;
        height: 400px;
        &.border {
          border: 1px solid #000;
        }
      }
      .notify-block {
        padding: 10px;
        border: 1px solid #aaa;
      }
      .subcontent-block {
        padding: 10px 0;
        border-top: 1px solid #ddd;
        h3 {
          margin: 5px 0;
          font-size: 1.1rem;
          color: #666;
        }
        h4 {
          margin: 5px 0;
          font-size: 1rem;
          color: #666;
        }
      }
    }
  }
}
</style>