<template>
  <div class="edit-password-modal">
    <modal-frame :showModalFlag="modalDisplay">
      <div class="modal-header">
        <h1>パスワード変更</h1>
      </div>
      <div class="modal-body">
        <attribute-in-form
          :labelText="'パスワード'"
          :necessary="true"
          :validationState="validatePassword.state"
          :validationMessage="validatePassword.message"
          :attributeNotify="'半角英数字及び記号!@のみ、6文字以上120文字以下'">
          <input type="text" size="30" v-model="inputPassword" />
          <div class="signin_pass_toggle" @click="togglePasswordDisplay">
            <custom-check-box
              :type="'small'"
              :active="passwordDisplay"
              :enabled="true"
            />
            パスワード表示
          </div>
        </attribute-in-form>
        <attribute-in-form
          :labelText="'パスワード(確認)'"
          :necessary="false"
          :validationState="validateConfirmation.state"
          :validationMessage="validateConfirmation.message"
          :attributeNotify="''">
          <input type="text" size="30" v-model="passwordConfirmation"/>
        </attribute-in-form>
        <div class="controll">
          <button class="cancel-btn" @click="cancelEdit">キャンセル</button>
          <button class="execute-btn" @click="update">更新</button>
        </div>
      </div>
    </modal-frame>
  </div>
</template>

<script>
import ModalFrame from '@/components/common/ModalFrame.vue'
import AttributeInForm from '@/components/common/AttributeInForm.vue'
import CustomCheckBox from '@/components/common/CustomCheckBox.vue'

export default {
  name: 'EditPasswordModal',
  
  components: {
    ModalFrame,
    AttributeInForm,
    CustomCheckBox
  },

  props: {
    modalDisplay: Boolean
  },

  data () {
    return {
      inputPassword: '',
      passwordConfirmation: '',
      passwordDisplay: false
    }
  },

  watch: {
    modalDisplay: { handler: 'initForm' }
  },

  computed: {
    passwordInputType () {
      if (this.passwordDisplay) {
        return 'text'
      } else {
        return 'password'
      }
    },

    validatePassword () {
      if (this.inputPassword.match(/^([a-zA-Z0-9]|!|@|\.|_|\/|-|\+|\(|\)){6,120}$/)) {
        return { state: 'valid', valid: true, message: '' }
      } else {
        return { state: 'invalid', valid: false, message: 'パスワードの書式が正しくありません。'}
      }
    },

    validateConfirmation () {
      if (!this.validatePassword.valid || this.passwordConfirmation.length <= 0 ) {
        return { state: 'none', valid: false, message: '' }
      } else if (this.inputPassword === this.passwordConfirmation) {
        return { state: 'valid', valid: true, message: '' }
      } else {
        return { state: 'invalid', valid: false, message: 'パスワードと同じではありません。'}
      }
    }
  },

  methods: {
    initForm () {
      this.inputPassword = ''
      this.passwordConfirmation = ''
    },

    togglePasswordDisplay () {
      this.passwordDisplay = !this.passwordDisplay
    },

    cancelEdit () {
      this.$emit('cancelEvent')
    },
    
    update () {
      this.$emit('updateEvent', this.inputPassword, this.passwordConfirmation)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/colorTable.scss';
.edit-user-modal {
  .modal-header {
    border-bottom: 1px solid #ddd;
    h1 {
      margin: 10px 0;
      font-size: 16px;
      color: #666;
      text-align: center;
    }
  }
  .modal-body {
    padding: 10px 20px 20px;
    .controll {
      text-align: right;
      button {
        margin-left: 20px;
        padding: 3px 10px;
        min-width: 100px;
        border: none;
        border-radius: 5px;
        font-weight: bold;
        &.cancel-btn {
          border: 1px solid #666;
          background-color: #fff;
          color: #666;
        }
        &.execute-btn {
          background-color: $phone-main-color;
          color: #fff;
        }
      }
    }
  }
}
</style>