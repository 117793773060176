<template>
  <div class="nb-signin-view">
    <div class="header">
      <img class="long-logo" src="/images/small_line_logo.png" />
    </div>
    <div class="user-selection">
      <div class="selectable-user" v-for="user in users" :key="user.id">
        <div class="line-items">
          <div class="title">表示名</div>
          <div class="value">{{ user.name }}</div>
        </div>
        <div class="line-items">
          <div class="title">ログインID</div>
          <div class="value">{{ user.account_name }}</div>
        </div>
        <div class="controll"><button class="select-btn" @click="signin(user)">このアカウントにログイン</button></div>
      </div>
      <div class="controll"><button class="signout-bth" @click="signoutNarrativeBook">別のナラティブブックアカウントを利用</button></div>
    </div>
  </div>
</template>

<script>
import { User } from '@/api'

export default {
  name: 'NBSigninView',

  data () {
    return {
      users: []
    }
  },

  mounted () {
    this.loadUsers()
  },

  methods: {
    loadUsers () {
      User.users_by_nb_signin().then(res => {
        console.log(res)
        this.users = res.data.users
      }).catch(err => {
        console.error(err)
      })
    },

    signin(user) {
      console.log(user.id)
      User.signin_with_nb_sso({ user_id: user.id }).then(res => {
        console.log(res)
        this.$router.push('/user_config_home')
      }).catch(err => {
        console.error(err)
      })
    },

    signoutNarrativeBook () {
      const request = new XMLHttpRequest()
      request.open("PUT", process.env.VUE_APP_NARRATIVEBOOK_HOST + "/api/signout", false) // `false` で同期リクエストになる
      request.send(null)
      if (request.status === 200) {
        console.log(request.responseText)
        var nbAuthURL = location.protocol 
        + '//' 
        + location.host 
        + '/api/authenticate/contact_nb_signin'
        console.log('narrative auth url: ' + nbAuthURL)
        window.location.href = nbAuthURL
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/colorTable.scss';
.nb-signin-view {
  .header {
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
  }
  .user-selection {
    margin: 20px auto;
    width: 400px;
    padding: 20px 30px;
    border: 1px solid #ddd;
    @include mediaQuery('phone') {
      width: auto;
      padding: 20px;
    }
    .selectable-user {
      padding: 10px 0;
      .line-items {
        display: flex;
        padding: 5px 0;
        .title {
          margin-right: 10px;
          width: 150px;
          text-align: right;
        }
        .value {
          font-weight: bold;
        }
      }
      .controll {
        text-align: right;
        .select-btn {
          padding: 3px 5px;
          border-radius: 5px;
          border: none;
          background-color: $phone-main-color;
          color: #fff;
        }
        .signout-bth {
          padding: 3px 5px;
          border-radius: 5px;
          border: none;
          background-color: $warning-color;
          color: #fff;
        }
      }
    }
  }
}
</style>