import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import native from './hybrid'
import { VueReCaptcha } from "vue-recaptcha-v3"
import * as gRecaptchaConf from '@/assets/constants/gReCaptchaConf.js'

var gSitekey = ''
if (process.env.NODE_ENV === 'development') {
  gSitekey = gRecaptchaConf.development.SITE_KEY
} else if (process.env.NODE_ENV === 'staging') {
  gSitekey = gRecaptchaConf.staging.SITE_KEY
} else if (process.env.NODE_ENV === 'production') {
  gSitekey = gRecaptchaConf.production.SITE_KEY
}

const app = createApp(App)
app.use(router)
app.use(native)
app.use(VueReCaptcha, { siteKey: gSitekey })
app.mount('#app')
