<template>
  <div class="input-user-info">
    <div class="header">
      <img src="/images/create_with_id.png" />
      <h2>IDとパスワードで作成</h2>
    </div>
    <attribute-in-form
      :labelText="'表示名'"
      :necessary="true"
      :validationState="validateName.state"
      :validationMessage="validateName.message"
      :attributeNotify="''">
      <input type="text" size="30" v-model="name" />
    </attribute-in-form>
    <attribute-in-form
      :labelText="'ログインID'"
      :necessary="true"
      :validationState="validateAccountName.state"
      :validationMessage="validateAccountName.message"
      :attributeNotify="'半角英数字大文字小文字、記号!@._/-+()6文字以上,120文字以下'">
      <input type="text" size="30" v-model="accountName" />
    </attribute-in-form>
    <attribute-in-form
      :labelText="'パスワード'"
      :necessary="true"
      :validationState="validatePassword.state"
      :validationMessage="validatePassword.message"
      :attributeNotify="'半角英数字大文字小文字、記号!@._/-+()6文字以上,120文字以下'">
      <input :type="passwordInputType" size="30" v-model="password" />
      <div class="signin_pass_toggle" @click="togglePasswordDisplay">
        <custom-check-box
          :type="'small'"
          :active="passwordDisplay"
          :enabled="true"
        />
        パスワード表示
      </div>
    </attribute-in-form>
    <attribute-in-form
      :labelText="'パスワード(確認)'"
      :necessary="true"
      :validationState="validatePasswordConfirmation.state"
      :validationMessage="validatePasswordConfirmation.message"
      :attributeNotify="''">
      <input :type="passwordInputType" size="30" v-model="passwordConfirmation" />
    </attribute-in-form>
    <attribute-in-form
      :labelText="'メールアドレス'"
      :necessary="false"
      :validationState="validateMail.state"
      :validationMessage="validateMail.message"
      :attributeNotify="''">
      <input type="text" size="30" v-model="email" />
    </attribute-in-form>
    <attribute-in-form
      :labelText="'メール認証トークン'"
      :necessary="false"
      :validationState="validateToken.state"
      :validationMessage="validateToken.message"
      :attributeNotify="''">
      <input type="text" size="12" v-model="mailValidateToken" />
      <button class="send-validation-btn" :class="{enable: validateMail.state == 'valid'}" @click="sendValidation">認証メールを送信</button>
    </attribute-in-form>
    <attribute-in-form
      :labelText="'詳細'"
      :necessary="false"
      :validationState="'none'"
      :validationMessage="''"
      :attributeNotify="''">
      <textarea class="description-input" v-model="description"></textarea>
    </attribute-in-form>
    <div class="controll">
      <button class="submit-btn" :class="{enable: validateForm}" @click="submitForm">送信</button>
    </div>
  </div>
</template>

<script>
import { User } from '@/api' 

import AttributeInForm from '@/components/common/AttributeInForm.vue'
import CustomCheckBox from '@/components/common/CustomCheckBox.vue'

export default {
  name: 'CreateUserInputUserInfo',

  components: {
    AttributeInForm,
    CustomCheckBox
  },

  data () {
    return {
      name: '',
      accountName: '',
      password: '',
      passwordConfirmation: '',
      email: '',
      mailValidateToken: '',
      description: '',
      passwordDisplay: false,
    }
  },

  computed: {
    passwordInputType () {
      if (this.passwordDisplay) {
        return 'text'
      } else {
        return 'password'
      }
    },

    validateName () {
      if (this.name && this.name.length > 0) {
        return { state: 'valid', valid: true, message: '' }
      } else {
        return { state: 'invalid', valid: false, message: '入力必須です' }
      }
    },

    validateAccountName () {
      if (this.accountName && this.accountName.match(/^([a-zA-Z0-9]|!|@|\.|_|\/|-|\+|\(|\)){6,120}$/gi)) {
        return { state: 'valid', valid: true, message: '' }
      } else {
        return { state: 'invalid', valid: false, message: '入力形式が正しくありません' }
      }
    },

    validatePassword () {
      if (this.password.match(/^([a-zA-Z0-9]|!|@|\.|_|\/|-|\+|\(|\)){6,120}$/)) {
        return { state: 'valid', valid: true, message: '' }
      } else {
        return { state: 'invalid', valid: false, message: 'パスワードの書式が正しくありません。'}
      }
    },

    validatePasswordConfirmation () {
      if (!this.validatePassword.valid || this.passwordConfirmation.length <= 0 ) {
        return { state: 'none', valid: false, message: '' }
      } else if (this.password === this.passwordConfirmation) {
        return { state: 'valid', valid: true, message: '' }
      } else {
        return { state: 'invalid', valid: false, message: 'パスワードと同じではありません。'}
      }
    },

    validateMail () {
      if (!this.email || this.email.length <= 0 ) {
        return { state: 'none', valid: true, message: '' }
      } else if (this.email.match(/.+@.+\..+/)) {
        return { state: 'valid', valid: true, message: '' }
      } else {
        return { state: 'invalid', valid: false, message: 'メールアドレスの形式が正しくありません。' }
      }
    },

    validateToken () {
      if (!this.mailValidateToken || this.mailValidateToken.length <= 0 ) {
        return { state: 'none', valid: true, message: '' }
      } else if (this.mailValidateToken.match(/\d{8}/)) {
        return { state: 'valid', valid: true, message: '' }
      } else {
        return { state: 'invalid', valid: false, message: '形式が正しくありません。半角数字8桁の値を入力してください。' }
      }
    },

    validateForm () {
      if (
        this.validateName.valid &&
        this.validateAccountName.valid &&
        this.validatePassword.valid &&
        this.validatePasswordConfirmation.valid &&
        this.validateMail.valid &&
        this.validateToken.valid
      ) {
        return true
      }
      return false
    }
  },

  mounted() {
    this.initForm()
  },

  methods: {
    initForm () {
      this.name = ''
      this.accountName = ''
      this.password = ''
      this.passwordConfirmation = ''
      this.email = ''
      this.mailValidateToken = ''
      this.description = ''
      this.passwordDisplay = false
    },

    togglePasswordDisplay () {
      this.passwordDisplay = !this.passwordDisplay
    },

    sendValidation () {
      console.log('sendValidation')
      if (this.name.length > 0 && this.email.length > 0) {
        console
        User.mailValidation({ name: this.name, email: this.email }).then(res => {
          console.log(res)
          this.$emit('validationMaildSentEvent', this.email)
        }).catch(err => {
          console.error(err)
        })
      } else {
        this.$emit('mailValidationNotifyEvent', '認証メール送信には名前とメールアドレスが必要です', '')
      }
    },

    submitForm() {
      console.log('submitForm')
      if (this.validateForm) {
        var formData = new FormData()
        formData.append('mail_validate_token', this.mailValidateToken)
        formData.append('user[name]', this.name)
        formData.append('user[account_name]', this.accountName)
        formData.append('user[password]', this.password)
        formData.append('user[password_confirmation]', this.passwordConfirmation)
        formData.append('user[description]', this.description)
        this.$emit('submitEvent', formData)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-user-info {
  border: 1px solid #ddd;
  padding: 10px 20px;
  .header {
    text-align: center;
    h2 {
      margin: 0;
      font-size: 20px;
      color: #666;
    }
  }
  .signin_pass_toggle {
    margin-left: 20px;
  }
  .send-validation-btn {
    margin-left: 10px;
    padding: 3px 5px;
    border: none;
    border-radius: 5px;
    background-color: #ddd;
    color: #fff;
    font-weight: bold;
    &.enable {
      background-color: #666;
    }
  }
  textarea {
    resize: none;
    width: 100%;
    height: 4rem;
  }
  .controll {
    text-align: right;
    button {
      margin-left: 20px;
      padding: 3px 10px;
      min-width: 100px;
      border: none;
      border-radius: 5px;
      font-weight: bold;
      &.cancel-btn {
        border: 1px solid #666;
        background-color: #fff;
        color: #666;
      }
      &.submit-btn {
        color: #fff;
        background-color: $phone-main-desable-color;
        &.enable {
          background-color: $phone-main-color;
        }
      }
    }
  }
}
</style>