<template>
  <div class="user-signin-view">
    <div class="title">
      <img class="title-icon" src="/images/hero-logotype.png" />
      <h1>ナラティブフォン ユーザーログイン</h1>
    </div>
    <!-- <div class="signin-block">
      <form id='own_signin_form' v-on:submit.prevent="doSignin">
        <div class="signin-input"><label>ログインID</label><input type="text" v-model="inputLoginId" ref="loginIDField"></div>
        <div class="signin-input"><label>パスワード</label><input :type="passwordInputType" id="password" v-model="inputPassword"></div>
        <div class="signin_pass_toggle" @click="togglePasswordDisplay">
          <custom-check-box
            :type="'small'"
            :active="passwordDisplay"
            :enabled="true"
          />
          パスワード表示
        </div>
        <button type="submit" class="btn success signin-btn">ログイン</button>
      </form>
    </div>
    <div class="forgot-notify">
      パスワードを忘れた場合は<a href="/reset_password">こちら</a>
    </div> -->
    <div class="nb-signin">
      <img class="nb-signin-logo" src="/images/narrative.iconset/icon_32x32@2x.png" />
      <button class="signin-narrativebook" @click="narrativebookSignin">ナラティブブックでログイン</button>
    </div>
    <confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
    />
  </div>
</template>

<script>
import { User } from '@/api'

// import CustomCheckBox from '@/components/common/CustomCheckBox.vue'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

import ConfirmDialogControll from '@/mixin/ConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [ConfirmDialogControll],

  name: 'UserSigninView',

  components: {
    // CustomCheckBox,
    ConfirmDialog
  },

  data () {
    return {
      passwordDisplay: false,
      inputLoginId: '',
      inputPassword: ''
    }
  },

  computed: {
    passwordInputType () {
      if (this.passwordDisplay) {
        return 'text'
      } else {
        return 'password'
      }
    }
  },

  methods: {
    togglePasswordDisplay () {
      console.log('toggle')
      this.passwordDisplay = !this.passwordDisplay
    },

    doSignin () {
      User.signin({ accountName: this.inputLoginId, password: this.inputPassword }).then(res => {
        console.log(res)
        this.$router.push('/user_config_home')
      }).catch(err => {
        console.error(err)
      })
    },

    narrativebookSignin () {
      console.log('new nb sso')
      this.showConfirmDialog(
        'ナラティブブックアカウントから作成', 
        'ナラティブブックの認証を経て、利用可能な可能なデータを取得します。', 
        DIALOG_TYPE.EXECUTE, 
        () => {
          var nbAuthURL = location.protocol 
          + '//' 
          + location.host 
          + '/api/authenticate/contact_nb_signin'
          console.log('narrative auth url: ' + nbAuthURL)
          window.location.href = nbAuthURL
        }
      )
    },

  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/colorTable.scss';
.user-signin-view {
  padding: 20px;
  .title {
    text-align: center;
    h1 {
      text-align: center;
      font-size: 20px;
    }
  }
  @include mediaQuery('phone') {
    .title{
      img {
        width: 300px;
      }
      h1 {
        font-size: 18px;
      }
    }
  }
  .signin-block {
    margin: 20px auto;
    padding: 20px;
    width: 300px;
    border: 1px solid #aaa;
    text-align: center;
    .signin-input {
      margin: 5px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      label {
        font-weight: bold;
      }
    }
    .signin_pass_toggle {
      margin: 5px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .signin-btn {
      display: inline-block;
      width: 200px;
      height: 30px;
      margin: 10px auto;
      color: #fff;
      font-weight: bold;
      background-color: $success-color;
      border: none;
      border-radius: 5px;
    }
  }
  .forgot-notify {
    text-align: center;
  }
  .nb-signin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px auto;
    padding: 20px;
    width: 300px;
    border: 1px solid #aaa;
    .signin-narrativebook {
      border: none;
      border-radius: 5px;
      height: 30px;
      background-color: $nb-normal-color;
      color: #fff;
      font-weight: bold;
    }
  }
}
</style>