<template>
  <div class="custom-checkbox" :class="displayStyle">
    <img src="/images/check_on_color_icon.png"
      class="on"
      v-if="type == 'color' && type != 'small'"
      @click="checkOff" />
    <img src="/images/check_on_icon.png"
      class="on"
      v-if="type != 'color' && type != 'small'"
      @click="checkOff" />
    <img src="/images/check_off_icon.png"
      class="off"
      v-if="type != 'small'"
      @click="checkOn"/>
    <img src="/images/check_disabled_icon.png"
      class="disabled"
      v-if="type != 'small'"
      @click="checkOn" />
    <img src="/images/check_disabled_off_icon.png"
      class="disabled-off"
      v-if="type != 'small'"
      @click="checkOn" />
    <img src="/images/check_on_icon_s.png"
      class="on"
      v-if="type == 'small'"
      @click="checkOff" />
    <img src="/images/check_off_icon_s.png"
      class="off"
      v-if="type == 'small'"
      @click="checkOn" />
    <img src="/images/check_disabled_icon_s.png"
      class="disabled"
      v-if="type == 'small'"
      @click="checkOn" />
    <img src="/images/check_disabled_off_icon_s.png"
      class="disabled-off"
      v-if="type == 'small'"
      @click="checkOn" />
  </div>
</template>

<script>
export default {
  name: 'CustomCheckBox',

  props: {
    type: String,
    active: Boolean,
    enabled: Boolean
  },

  computed: {
    displayStyle () {
      var classStr = this.type + ' '
      if (this.enabled) {
        if (this.active) {
          return classStr + 'active'
        } else {
          return classStr + 'inactive'
        }
      } else {
        if (this.active) {
          return classStr + 'disabled'
        } else {
          return classStr + 'disabled-off'
        }
      }
    }
  },

  methods: {
    checkOn () {

    },

    checkOff () {

    }
  }

}
</script>

<style lang="scss">
.custom-checkbox {
  display: inline-block;
  margin: 0;
  padding: 0;
  height: 18px;
  img {
    height: 18px;
  }
  &.small {
    height: 15px;
    img {
      height: 15px;
      width: 18px;
    }
  }
  &.active {
    .on-color {
      display: inline-block;
    }
    .on {
      display: inline-block;
    }
    .off {
      display: none;
    }
    .disabled {
      display: none;
    }
    .disabled-off {
      display: none;
    }
  }
  &.inactive {
    .on-color {
      display: none;
    }
    .on {
      display: none;
    }
    .off {
      display: inline-block;
    }
    .disabled {
      display: none;
    }
    .disabled-off {
      display: none;
    }
  }
  &.disabled {
    .on-color {
      display: none;
    }
    .on {
      display: none;
    }
    .off {
      display: none;
    }
    .disabled {
      display: inline-block;
    }
    .disabled-off {
      display: none;
    }
  }
  &.disabled-off {
    .on-color {
      display: none;
    }
    .on {
      display: none;
    }
    .off {
      display: none;
    }
    .disabled {
      display: none;
    }
    .disabled-off {
      display: inline-block;
    }
  }
}
</style>
