<template>
  <div class="term-of-use-view">
    <term-of-use
      :lang="lang"
      v-on:switchLangEvent="switchLang" />
  </div>
</template>

<script>
import TermOfUse from '@/components/common/TermOfUse.vue'

export default{
  name: 'temOfUseView',

  components: {
    TermOfUse
  },

  data () {
    return {
      lang: 'jp'
    }
  },

  mounted () {
    this.storeQuery()
  },

//  watch: {
//    '$route': { handler: 'storeQuery', immediate: true }
//  },

  methods: {
    storeQuery () {
      if (this.$route.params.lang[0]) {
        this.lang = this.$route.params.lang[0]
      }
    },

    switchLang () {
      if (this.lang === 'jp') {
        this.lang = 'en'
      } else {
        this.lang = 'jp'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$breakpoints: (
  "phone": "screen and (max-width: 420px)",
  "tablet": "screen and (max-width: 1023px)",
  "pc": "screen and (min-width: 1024px)"
);

// メディアクエリ
@mixin mediaQuery($breakpoint: sp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

.term-of-use-view {
  padding: 30px 50px;
  background-color: #fff;
  @include mediaQuery('phone') {
    padding: 10px;
  }
}
</style>