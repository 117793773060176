<template>
  <div class="edit-user-modal">
    <modal-frame :showModalFlag="modalDisplay">
      <div class="modal-header">
        <h1>施設編集</h1>
      </div>
      <div class="modal-body">
        <attribute-in-form
          :labelText="'表示名'"
          :necessary="true"
          :validationState="validateName.state"
          :validationMessage="validateName.message"
          :attributeNotify="''">
          <input type="text" size="30" v-model="editUser.name" />
        </attribute-in-form>
        <attribute-in-form
          :labelText="'詳細'"
          :necessary="false"
          :validationState="'none'"
          :validationMessage="''"
          :attributeNotify="''">
          <textarea class="description-input" v-model="editUser.description"></textarea>
        </attribute-in-form>
        <attribute-in-form
          :labelText="'メールアドレス'"
          :necessary="false"
          :validationState="validateMail.state"
          :validationMessage="validateMail.message"
          :attributeNotify="''">
          <input type="text" size="40" v-model="editUser.email" />
        </attribute-in-form>
        <attribute-in-form
          :labelText="'メール認証トークン'"
          :necessary="false"
          :validationState="validateToken.state"
          :validationMessage="validateToken.message"
          :attributeNotify="''">
          <input type="text" size="12" v-model="mailValidateToken" />
          <button class="send-validation-btn" :class="{enable: validateMail.state == 'valid'}" @click="sendMailValidation">認証メールを送信</button>
        </attribute-in-form>
        <div class="controll">
          <button class="cancel-btn" @click="cancelEdit">キャンセル</button>
          <button class="execute-btn" @click="update">更新</button>
        </div>
      </div>
    </modal-frame>
  </div>
</template>

<script>
import ModalFrame from '@/components/common/ModalFrame.vue';
import AttributeInForm from '@/components/common/AttributeInForm.vue';

export default {
  name: 'EditUserModal',

  components: {
    ModalFrame,
    AttributeInForm
  },

  props: {
    modalDisplay: Boolean,
    user: Object
  },

  data () {
    return {
      editUser: {},
      mailValidateToken: ''
    }
  },

  watch: {
    modalDisplay: { handler: 'initForm' }
  },

  computed: {
    validateName () {
      if (this.editUser.name && this.editUser.name.length > 0) {
        return { state: 'valid', valid: true, message: '' }
      } else {
        return { state: 'invalid', valid: false, message: '入力必須です' }
      }
    },

    validateMail () {
      if (!this.editUser.email || this.editUser.email.length <= 0 ) {
        return { state: 'none', valid: true, message: '' }
      } else if (this.editUser.email.match(/.+@.+\..+/)) {
        return { state: 'valid', valid: true, message: '' }
      } else {
        return { state: 'invalid', valid: false, message: 'メールアドレスの形式が正しくありません。' }
      }
    },

    validateToken () {
      if (!this.mailValidateToken || this.mailValidateToken.length <= 0 ) {
        return { state: 'none', valid: true, message: '' }
      } else if (this.mailValidateToken.match(/\d{8}/)) {
        return { state: 'valid', valid: true, message: '' }
      } else {
        return { state: 'invalid', valid: false, message: '形式が正しくありません。半角数字8桁の値を入力してください。' }
      }
    }
  },

  methods: {
    initForm () {
      if (this.modalDisplay == true) {
        this.initTarget()
      }
    },

    initTarget () {
      this.editUser.name = this.user.name
      this.editUser.email = this.user.email
      this.editUser.description = this.user.description
      this.mailValidateToken = ''
    },

    sendMailValidation () {
      this.$emit('sendMailValidationEvent', this.editUser.email)
    },

    cancelEdit () {
      this.$emit('cancelEditEvent')
    },

    update () {
      this.$emit('updateEvent', this.editUser, this.mailValidateToken)
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/colorTable.scss';
.edit-user-modal {
  .modal-header {
    border-bottom: 1px solid #ddd;
    h1 {
      margin: 10px 0;
      font-size: 16px;
      color: #666;
      text-align: center;
    }
  }
  .modal-body {
    padding: 10px 20px 20px;
    .description-input {
      resize: none;
      width: 100%;
      height: 4rem;
    }
    .send-validation-btn {
      margin-left: 10px;
      padding: 3px 5px;
      border: none;
      border-radius: 5px;
      background-color: #ddd;
      color: #fff;
      font-weight: bold;
      &.enable {
        background-color: #666;
      }
    }
    .controll {
      text-align: right;
      button {
        margin-left: 20px;
        padding: 3px 10px;
        min-width: 100px;
        border: none;
        border-radius: 5px;
        font-weight: bold;
        &.cancel-btn {
          border: 1px solid #666;
          background-color: #fff;
          color: #666;
        }
        &.execute-btn {
          background-color: $phone-main-color;
          color: #fff;
        }
      }
    }
  }
}
</style>