<template>
  <div class="reconnect-zoom-account-view">
    <div class="header">
      <img class="title-icon" src="/images/hero-logotype.png" />
      <h1>Zoomアカウントの再接続</h1>
    </div>
    <div class="content-block">
      <div class="line-block" v-if="user">
        <div class="line-items">
          <div class="title">ユーザー表示名</div>
          <div class="value">{{ user.name }}</div>
        </div>
        <div class="line-items">
          <div class="title">ユーザー詳細</div>
          <div class="value">{{ user.description }}</div>
        </div>
        <div class="line-items">
          <div class="title">ログインID</div>
          <div class="value">{{ user.account_name }}</div>
        </div>
        <div class="line-items">
          <div class="title">メールアドレス</div>
          <div class="value">{{ user.email }}</div>
        </div>
      </div>
      <div class="line-block" v-if="!user">
        ユーザーが取得できていません
      </div>
      <div class="line-block" v-if="phoneDevice">
        <div class="line-items">
          <div class="title">デバイス情報</div>
          <div class="value">{{ phoneDevice.phone_device_description }}</div>
        </div>
        <div class="line-items">
          <div class="title">デバイスID</div>
          <div class="value">{{ phoneDevice.phone_device_id }}</div>
        </div>
        <div class="line-items">
          <div class="title">Pushサーバー種別</div>
          <div class="value">{{ pushServerStr(phoneDevice) }}</div>
        </div>
      </div>
      <div class="line-block" v-if="!phoneDevice">
        デバイスが取得できていません
      </div>
      <div class="line-block" v-if="user && phoneDevice">
        <div class="controll">
          <button class="edit-btn" @click="reconnectZoom">再接続</button>
        </div>
      </div>
    </div>
    <confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
    />
  </div>
</template>

<script>
import { User, PhoneDevice } from '@/api'

import ConfirmDialog from '@/components/common/ConfirmDialog.vue'
import ConfirmDialogControll from '@/mixin/ConfirmDialogControll.js'

import * as DEVICE_CONST from '@/assets/constants/phoneDeviceConstants.js'

export default{
  mixins: [ConfirmDialogControll],

   components: {
    ConfirmDialog
   },

   data () {
    return {
      user: null,
      phoneDevice: null,
      deviceType: '',
      deviceAuthenticateToken: '',
      loginID: '',
      phoneDeviceID: ''
    }
   },

   watch: {
    '$route': { handler: 'prepareConfiguration', immediate: true },
   },

   methods: {
    prepareConfiguration () {
      this.readQeury()
      // this.loadUser()
    },

    readQeury () {
      console.log(this.$route.query)
      if (this.$route.query.type) {
        this.deviceType = this.$route.query.type
      }
      if (this.$route.query.device_authenticate_token) {
        this.deviceAuthenticateToken = this.$route.query.device_authenticate_token
      }
      if (this.$route.query.login_id) {
        this.loginID = this.$route.query.login_id
      }
      if (this.$route.query.phone_device_id) {
        this.phoneDeviceID = this.$route.query.phone_device_id
      }
      if (this.deviceAuthenticateToken.length > 0 && this.loginID.length > 0 && this.phoneDeviceID.length > 0) {
        // MEMO: try to device signin
        PhoneDevice.signInByChallengeToken({ loginID: this.loginID, deviceID: this.phoneDeviceID, deviceAuthenticateToken: this.deviceAuthenticateToken }).then(res => {
          console.log('device auth succeeded.')
          console.log(res)
          console.log('gonna fetch current user and device')
          this.loadUser()
        })
      } else {
        this.loadUser()
      }
    },

    loadUser () {
      User.fetchCurrentSignin().then(res => {
        console.log(res)
        this.user = res.data.user
        this.phoneDevice = res.data.phone_device
        console.log('user and device loaded....')
        this.reconnectZoom()
      }).catch(err => {
        console.log(err)
      })
    },

    pushServerStr(device) {
      if (device.push_target == DEVICE_CONST.PUSH_SERVER_APNS) {
        return 'APNs'
      } else if (device.push_target == DEVICE_CONST.PUSH_SERVER_FIREBASE) {
        return 'Firebase'
      } else {
        return '-----'
      }
    },

    reconnectZoom () {
      console.log('call, reconnect_zoom,')
      console.log(this.phoneDevice.push_target)
      if (this.phoneDevice.push_target == DEVICE_CONST.PUSH_SERVER_APNS) {
        var iosZoomAuthURL = location.protocol 
          + '//' 
          + location.host 
          + '/api/user/contact_zoom_auth?retry_type=ios'
        console.log('zoom auth url: ' + iosZoomAuthURL)
        window.location.href = iosZoomAuthURL
      } else if (this.phoneDevice.push_target == DEVICE_CONST.PUSH_SERVER_FIREBASE) {
        var androidZoomAuthURL = location.protocol 
          + '//' 
          + location.host 
          + '/api/user/contact_zoom_auth?retry_type=android'
        console.log('zoom auth url: ' + androidZoomAuthURL)
        window.location.href = androidZoomAuthURL
      // }
      // if (this.deviceType == 'ios') {
      //   var iosZoomAuthURL = location.protocol 
      //     + '//' 
      //     + location.host 
      //     + '/api/user/contact_zoom_auth?retry_type=ios'
      //   console.log('zoom auth url: ' + iosZoomAuthURL)
      //   window.location.href = iosZoomAuthURL
      // } else if (this.deviceType == 'android') {
      //   var androidZoomAuthURL = location.protocol 
      //     + '//' 
      //     + location.host 
      //     + '/api/user/contact_zoom_auth?retry_type=android'
      //   console.log('zoom auth url: ' + androidZoomAuthURL)
      //   window.location.href = androidZoomAuthURL
      } else {
          this.showNotifyDialog('Zoomへの接続失敗', 'ユーザーとデバイスの接続情報が消失しています。Zoomへの接続操作を継続できません。\n一度アプリを終了してから、次回は「インポート」の操作を行ってください。')
      }
    }
   }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/colorTable.scss';
.reconnect-zoom-account-view {
  .header {
    padding: 20px;
    text-align: center;
    img {
      display: block;
      margin: 10px auto;
      &.title-icon {
        width: 390px;
        @include mediaQuery('phone') {
          width: calc(100% - 40px);
        }
      }
    }
    h1 {
      font-size: 21px;
      color: #666;
    }
  }
  .content-block {
    padding: 20px 40px;
    h2 {
      margin: 10px 0;
      font-size: 21px;
      text-align: center;
      color: #666;
    }
    h3 {
      margin: 5px 0;
      font-size: 18px;
      color: #666;
    }
    .line-block {
      border: 1px solid #ddd;
      margin-bottom: 10px;
      padding: 10px;
      .line-items {
        display: flex;
        padding: 5px 0;
        span {
          display: inline-block;
        }
        .title {
          margin-right: 10px;
          width: 250px;
          text-align: right;
        }
        .value {
          font-weight: bold;
        }
      }
      .controll {
        display: flex;
        justify-content: flex-end;
        button {
          display: inline-block;
          border: none;
          border-radius: 5px;
          margin: 0 10px;
          padding: 5px 10px;
          font-weight: bold;
          &.destroy-btn {
            background-color: $danger-color;
            color: #fff;
          }
          &.edit-btn {
            background-color: $warning-color;
            color: #fff;
          }
          &.change-password-btn {
            background-color: #666;
            color: #fff;
          }
        }
      }
    }
    @include mediaQuery('phone') {
      padding: 10px;
      .line-block .line-items .title {
        width: 150px;
      }
    }
  }
  .connection-result-column {
    margin: 10px 20px;
    padding: 10px;
    border: 1px solid #ddd;
    h2 {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
    .message {
      line-height: 1.5em;
    }
    .controll {
      text-align: center;
      button.retry-btn {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: $zoom-main-color;
        font-weight: bold;
        color: #fff;
      }
    }
  }
}
</style>