import axios from './AxiosInstance'

export default {
  create: ({ formData }) => {
    return new Promise((resolve, reject) => {
      axios.currentCsrfToken().then(token => {
        axios.httpClient({
          method: 'post',
          url: '/api/phone_device',
          headers: {
            'X-HTTP-Method-Override': 'POST',
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': token
          },
          withCredentials: true,
          data: formData
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  fetch: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: '/api/phone_device/list',
        headers: {
          'X-HTTP-Method-Override': 'GET',
        },
        withCredentials: true
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },

  destroy: ({ id }) => {
    var formData = new FormData()
    formData.append('id', id)
    return new Promise((resolve, reject) => {
      axios.currentCsrfToken().then(token => {
        axios.httpClient({
          method: 'delete',
          url: '/api/user',
          headers: {
            'X-HTTP-Method-Override': 'DELETE',
            'Content-Type': 'multipart/form-data',
            'X-CSRF-TOKEN': token,
          },
          withCredentials: true,
          data: formData
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    })
  },

  signInByChallengeToken: ({ loginID, deviceID, deviceAuthenticateToken }) => {
    return new Promise((resolve, reject) => {
      axios.httpClient.get('/api/authenticate/app_signin_challenge', { withCredentials: true }).then(res => {
        console.log('challenge got,')
        console.log(res.data.challenge)
        var challenge = res.data.challenge
        if (challenge.length > 0) {
          // crypto-jsライブラリをインポートする
          const CryptoJS = require('crypto-js')
          const message = deviceAuthenticateToken + challenge
          const hash = CryptoJS.SHA256(message)
          const encodedText = hash.toString(CryptoJS.enc.Hex)
          // const encodedText = hash.toString(CryptoJS.enc.Base64)
          console.log('encoded hash, ')
          console.log(encodedText)
          var formData = new FormData()
          formData.append('login_id', loginID)
          formData.append('phone_device_id', deviceID)
          formData.append('token', encodedText)
          axios.currentCsrfToken().then(token => {
            axios.httpClient({
              method: 'post',
              url: '/api/authenticate/app_signin',
              headers: {
                'X-HTTP-Method-Override': 'POST',
                'Content-Type': 'multipart/form-data',
                'X-CSRF-TOKEN': token,
              },
              withCredentials: true,
              data: formData
            }).then(res => {
              console.log(res)
              resolve(res)
            }).catch(error => {
              console.error(error)
              reject(error)
            })
          })
        } else {
          reject()
        }
      }).catch(error => {
        reject(error)
      })
    })
  }
}