import axios from './AxiosInstance'

export default {
  fetch: () => {
    return new Promise((resolve, reject) => {
      axios.httpClient({
        method: 'get',
        url: '/api/address_category/list_with_entries',
        headers: {
          'X-HTTP-Method-Override': 'GET',
        },
        withCredentials: true
      }).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
