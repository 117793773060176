<template>
  <div class="select-create-method">
    <div class="new-user-create-selection" @click="newWithNarrativeBook">
      <div class="title">
        <img src="/images/create_with_nb.png" />
        <div>ナラティブブックアカウントから作成</div>
      </div>
      <div class="description">
        ナラティブブックに登録された情報を利用してナラティブフォンのアカウントを作成します。<br/>
        作成後、ナラティブブックのアドレス帳から、連絡先のインポートを行えます。<br/>
        また、ナラティブブックからナラティブフォンを呼び出すこともできるようになります。<br/>
      </div>
    </div>
    <!-- <div class="new-user-create-selection" @click="newWithIdPassword">
      <div class="title">
        <img src="/images/create_with_id.png" />
        <div>IDとパスワードで作成</div>
      </div>
      <div class="description">
       全く新規にIDとパスワードを設定してアカウントを作成します。<br/>
       連絡先の追加は、QRコードの交換などで行う必要があります。<br/>
       ナラティブブックとの連携は、あとからでも行なえます。
      </div>
    </div> -->
    <div class="new-user-create-selection" @click="importWithNarrativeBook">
      <div class="title">
        <img src="/images/import_with_nb.png" />
        <div>ナラティブブックアカウントからインポート</div>
      </div>
      <div class="description">
        すでに作成されたナラティブフォンのアカウントをインポートします。<br/>
        ナラティブフォンのアカウントがナラティブブックアカウントから作成されている場合はこちらを選んでください。<br/>
        インポートが完了すると、他のデバイスで登録された内容がそのまま移されます。
      </div>
    </div>
    <!-- <div class="new-user-create-selection" @click="importWithIdPassword">
      <div class="title">
        <img src="/images/import_with_id.png" />
        <div>IDとパスワードで作成されたアカウントをインポート</div>
      </div>
      <div class="description">
        すでに作成されたナラティブフォンのアカウントをインポートします。<br/>
        ナラティブフォンのアカウントがIDとパスワードから作成されている場合はこちらを選んでください。<br/>
        インポートが完了すると、他のデバイスで登録された内容がそのまま移されます。
      </div>
    </div> -->
  </div>
</template>

<script>
export default{
  name: 'CreateUserSelectCreateMethod',

  methods: {
    newWithNarrativeBook () {
      this.$emit('createWithNarrativebookSSOEvent')
    },

    newWithIdPassword () {
      this.$emit('createWithIdAndPasswordEvent')
    },

    importWithNarrativeBook () {
      this.$emit('importWithNarrativebookSSOEvent')
    },

    importWithIdPassword () {
      this.$emit('importWithIdAndPasswordEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
.select-create-method {
  .new-user-create-selection {
    margin: 10px;
    padding: 10px;
    border: 1px solid #aaa;
    cursor: pointer;
    .title {
      margin-bottom: 10px;
      font-size: 1.1rem;
      font-weight: bold;
    }
    .description {
      line-height: 1.4rem;
    }
  }
}
</style>