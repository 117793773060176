<template>
  <div class="present-user-select-view">
    <div class="header">
      <img class="title-icon" src="/images/hero-logotype.png" />
      <img class="method-icon" src="/images/import_with_nb.png" />
      <h1>ナラティブブックアカウントからインポート</h1>
    </div>
    <div class="account-list" v-if="!userCreateResult">
      <div class="account-block" v-for="(account, index) in accounts" :key="'ac'+index">
        <div class="line-item"><span class="title">名前 : </span><span class="value name">{{ account.name }}</span></div>
        <div class="line-item"><span class="title">NBID : </span><span class="value">{{ account.nbid }}</span></div>
        <div class="line-item"><span class="title">メール : </span><span class="value">{{ account.email }}</span></div>
        <div class="line-item"><span class="title">AuthorID : </span><span class="value">{{ account.author_id }}</span></div>
        <div class="line-item"><span class="title">種別 : </span><span class="value">{{ accountType(account) }}</span></div>
        <div class="line-item"><span class="title">状態 : </span><span class="value">{{ accountState(account) }}</span></div>
        <div class="controll" v-if="account.available"><button @click="selectAccount(account)">このアカウントを利用</button></div>
        <div class="message" v-if="!account.available">デバイス登録済み<button @click="sendAccountInfo(account)">アカウント情報を再取り込み</button></div>
      </div>
      <div class="account-block">
        <div class="controll">
          <button class="cancel-btn" @click="backToMethodSelection">戻る</button>
        </div>
      </div>
    </div>
    <div class="create-result account-block" v-if="userCreateResult">
      <div class="notify">
        ユーザーにデバイスを登録しました。
      </div>
      <div class="line-item"><span class="title">名前 : </span><span class="value name">{{ userCreateResult.user_name }} </span></div>
      <div class="line-item"><span class="title">アカウント名 : </span><span class="value">{{ userCreateResult.user_account_name }}</span></div>
      <div class="line-item"><span class="title">NBID : </span><span class="value">{{ userCreateResult.nbid }}</span></div>
      <div class="line-item"><span class="title">メールアドレス : </span><span class="value">{{ userCreateResult.user_email }}</span></div>
      <div class="line-item"><span class="title">詳細 : </span><span class="value">{{ userCreateResult.user_description }}</span></div>
      <div class="line-item"><span class="title">デバイス情報 : </span><span class="value">{{ userCreateResult.phone_device_description }}</span></div>
      <div class="connect-to-zoom-column" v-if="needToConnectZoom">
        <h2>Zoomとのアカウント接続</h2>
        <div class="description">
          このアカウントは、まだZoomとの接続が行われていません。
          通話に際してはZoomアカウントとの接続が必要です。
          下のボタンを押して、接続したいZoomアカウントにログインし、ナラティブフォンアプリを承認してください。
        </div>
        <div class="zoom-controll">
          <button class="connect-to-zoom" @click="connectToZoom">Zoomに接続する</button>
        </div>
      </div>
    </div>
    <confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
    />
  </div>
</template>

<script>
import { User } from '@/api'

import ConfirmDialog from '@/components/common/ConfirmDialog.vue'
import ConfirmDialogControll from '@/mixin/ConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'
import * as PHONE_DEIVCE_CONSTANTS from '@/assets/constants/phoneDeviceConstants.js'

export default{
  mixins: [ConfirmDialogControll],

  name: 'PresentUserSelectView',

  components: {
    ConfirmDialog
  },

  data () {
    return {
      accounts: [],
      phoneDevice: {},
      createToken: null,
      userCreateResult: null
    }
  },

  watch: {
    '$route': { handler: 'storeQuery', immediate: true }
  },

  computed: {
    needToConnectZoom () {
      if(this.userCreateResult.ios_zoom_available && this.userCreateResult.phone_device_push_target == PHONE_DEIVCE_CONSTANTS.PUSH_SERVER_APNS) {
        return false
      } else if (this.userCreateResult.android_zoom_available && this.userCreateResult.phone_device_push_target == PHONE_DEIVCE_CONSTANTS.PUSH_SERVER_FIREBASE) {
        return false
      } else {
        return true
      }
    }
  },

  methods: {
    storeQuery () {
      if (this.$route.query.result_code) {
        User.account_result({ code: this.$route.query.result_code }).then(res => {
          this.accounts = res.data.availables.authors
          this.phoneDevice = res.data.availables.phone_device
          this.createToken = res.data.availables.create_token
        })
      }
      // if (this.$route.query.availables) {
      //   var availables = JSON.parse(this.$route.query.availables)
      //   this.accounts = availables.authors
      //   this.phoneDevice = availables.phone_device
      //   this.createToken = availables.create_token
      // }
    },

    accountType(account) {
      if (account.nb_organization_id) {
        return '施設代表'
      } else if (/mem.+/g.test(account.nb_uthor_id)) {
        return '施設職員'
      } else {
        return '一般利用者'
      }
    },

    accountState(account) {
      if (account.narrative_phone_id) {
        return '連携済み'
      } else {
        return '未連携'
      }
    },

    backToMethodSelection () {
      if (this.phoneDevice) {
        var path = '/user/new_user_with_device?phone_device_description=' 
          + this.phoneDevice.phone_device_description 
          + '&phone_device_token=' + this.phoneDevice.phone_device_token 
          + '&push_target=' + this.phoneDevice.push_target
          + '&second_try=YES'
        this.$router.push(path)
      } else {
        this.showNotifyDialog('接続情報の取得失敗', '接続情報が消失しています。デバイスの登録操作を継続できません。\n一度アプリを終了してから、次回は「インポート」の操作を行ってください。')
      }
    },

    selectAccount(account) {
      this.showConfirmDialog(
        '登録済みアカウントにデバイスを登録', 
        '登録済みのアカウント' + account.name + 'にデバイスを登録します。', 
        DIALOG_TYPE.EXECUTE, 
        () => {
          this.createDeviceToUser(account)
        }
      )
    },

    sendAccountInfo (account) {
      this.showConfirmDialog(
        '登録済み情報を取得', 
        '登録済みのアカウント' + account.name + 'の情報を取得します。', 
        DIALOG_TYPE.EXECUTE, 
        () => {
          this.createDeviceToUser(account)
        }
      )
    },

    createDeviceToUser (account) {
      var formData = new FormData()
      formData.append('create_token', this.createToken)
      formData.append('target_user_id', account.id)
      formData.append('phone_device[phone_device_description]', this.phoneDevice.phone_device_description)
      formData.append('phone_device[phone_device_token]', this.phoneDevice.phone_device_token)
      formData.append('phone_device[push_target]', this.phoneDevice.push_target)
      User.createPhoneDevice({ formData: formData }).then(res => {
        console.log(res)
        var responseData = {
            user_id: res.data.user.id,
            nbid: res.data.user.nb_id,
            user_name: res.data.user.name,
            user_account_name: res.data.user.account_name,
            user_email: res.data.user.email,
            user_author_id: res.data.user.nb_author_id,
            user_description: res.data.user.description,
            narrative_phone_id: res.data.user.narrative_phone_id,
            ios_zoom_available: res.data.user.ios_zoom_available,
            android_zoom_available: res.data.user.android_zoom_available,
            phone_device_id: res.data.phone_device.phone_device_id,
            phone_device_authenticate_token: res.data.phone_device.device_authenticate_token,
            phone_device_description: res.data.phone_device.phone_device_description,
            phone_device_push_target: res.data.phone_device.push_target,
          }
          this.userCreateResult = responseData
          if (this.$native) {
            this.$native.event('deviceCreatedEvent', responseData)
            if (!this.needToConnectZoom) {
              this.$native.event('zoomUserActivatedEvent', responseData)
            }
          }
      }).catch(err => {
        console.error(err)
      })
    },

    connectToZoom () {
      var zoomAuthURL = location.protocol 
          + '//' 
          + location.host 
          + '/api/user/contact_zoom_auth'
      console.log('zoom auth url: ' + zoomAuthURL)
      window.location.href = zoomAuthURL
    }

  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/colorTable.scss';
.present-user-select-view {
  .header {
    padding: 20px;
    text-align: center;
    img {
      display: block;
      margin: 10px auto;
      &.title-icon {
        width: 390px;
        @include mediaQuery('phone') {
          width: calc(100% - 40px);
        }
      }
      &.method-icon {
        height: 40px;
      }
    }
    h1 {
      font-size: 21px;
      color: #666;
    }
  }
  .account-list {
    padding: 0 20px;
  }
  .create-result {
    padding: 0 20px;
    .notify {
      margin: 10px 0;
      padding: 10px;
      border: 1px solid #ddd;
      color: $danger-color;
    }
  }
  .connect-to-zoom-column {
    padding: 10px 20px;
    border: 1px solid $danger-color;
    h2 {
      font-size: 20px;
      color: #666;
    }
    .description {
      line-height: 1.3rem;
    }
    .zoom-controll {
      padding: 10px;
      text-align: center;
      button.connect-to-zoom {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background-color: $zoom-main-color;
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .account-block {
    margin: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    .line-item {
      margin: 5px 0;
      padding: 5px 0;
      border-bottom: 1px solid #ddd;
      span {
        display: inline-block;
        &.title {
          color: #333;
          font-size: 14px;
        }
        &.value {
          margin-left: 10px;
          font-weight: bold;
          &.name {
            font-size: 20px;
          }
        }
      }
    }
    .controll {
      text-align: right;
      button {
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        font-weight: bold;
        color: #fff;
        background-color: $phone-main-color;
      }
    }
    .message {
      text-align: center;
    }
    button {
      margin: 10px 20px;
      padding: 5px 10px;
      border: none;
      border-radius: 5px;
      font-weight: bold;
      color: #fff;
      background-color: $phone-main-color;
    }
  }
}</style>