<template>
  <div class="import-user-with-signin">
    <div class="header">
      <img src="/images/import_with_id.png" />
      <h2>IDとパスワードで作成されたアカウントをインポート</h2>
    </div>
    <div class="signin-block">
      <form id='own_signin_form' v-on:submit.prevent="submitForm">
        <div class="signin-input"><label>ログインID</label><input type="text" v-model="accountName"></div>
        <div class="signin-input"><label>パスワード</label><input :type="passwordInputType" id="password" v-model="password"></div>
        <div class="signin_pass_toggle" @click="togglePasswordDisplay">
          <custom-check-box
            :type="'small'"
            :active="passwordDisplay"
            :enabled="true"
          />
          パスワード表示
        </div>
        <button type="submit" class="btn success signin-btn">送信</button>
      </form>
    </div>
  </div>
</template>

<script>
import { User } from '@/api' 

import CustomCheckBox from '@/components/common/CustomCheckBox.vue'

export default {
  name: 'ImportUserWithSignin',

  components: {
    CustomCheckBox,
  },

  data () {
    return {
      passwordDisplay: false,
      accountName: '',
      password: ''
    }
  },

  computed: {
    passwordInputType () {
      if (this.passwordDisplay) {
        return 'text'
      } else {
        return 'password'
      }
    }
  },

  methods: {
    togglePasswordDisplay () {
      console.log('toggle')
      this.passwordDisplay = !this.passwordDisplay
    },

    submitForm () {
      if (this.accountName.length > 0 && this.password.length > 0) {
        User.signin({ accountName: this.accountName, password: this.password }).then(res => {
          console.log(res)
          if (res.data.state === 'success') {
            this.$emit('signinSucceedEvent')
          }
        }).catch(err => {
          console.error(err)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/colorTable.scss';
.import-user-with-signin {
  .header {
    text-align: center;
    h2 {
      margin: 0;
      font-size: 20px;
      color: #666;
    }
  }
  .signin-block {
    margin: 20px auto;
    padding: 20px;
    width: 300px;
    border: 1px solid #aaa;
    text-align: center;
    .signin-input {
      margin: 5px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      label {
        font-weight: bold;
      }
    }
    .signin_pass_toggle {
      margin: 5px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .signin-btn {
      display: inline-block;
      width: 200px;
      height: 30px;
      margin: 10px auto;
      color: #fff;
      font-weight: bold;
      background-color: $success-color;
      border: none;
      border-radius: 5px;
    }
  }

}
</style>