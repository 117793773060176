<template>
  <div class="term-of-use">
    <div class="lang-content" v-if="lang !== 'en'">
      <h1>ナラティブフォン利用規約<a href="#" @click="switchLang" class="switch-lang">English</a></h1>
      <div class="tou-content jp">
        <h1>ナラティブフォンシステム利用規約</h1>
        <h2>(目的等)</h2>
        <h3>第1条</h3>
        <p>この規約(以下「本規約」といいます。)は、クロスケアフィールド株式会社(以下「本法人」といいます。)が「ナラティブフォン」の名称で提供するシステム(以下「本システム」といいます。)の利用に関する条件を、本システムを利用する者(以下「利用者」といいます。)と本法人との間で定めるものです。</p>
        <p>また、本システムは、Zoom ビデオコミュニケーションズ株式会社(英:Zoom Video Communications, Inc.)(以下「Zoom 社」といいます。)のサービス(Web会議サービス Zoomを含み、以下「Zoom」といいます。)を利用しています(以下、「本システムの利用」又は「本システムを利用」と称する場合、特段の記載がない限り、本システムを介在した又は本システムを用いたZoomの利用を含むものとします。)。</p>
        <p>利用者が本システムを利用する場合、Zoom のアカウントが必要になります。利用者は、本システムを利用する場合、Zoom 社のZoomの利用に関わる一切の規約(その名称を問いません。以下に規約等のウェブサイトを例示しますが、これに限りません。以下「Zoom規約」といいます。)に同意するほか、本規約への同意が必要になります。利用者は、本システムを利用いただく前に、必ず本規約及びZoom規約を十分にお読みください。なお、利用者が本システムの利用を開始した場合、利用者は本規約に同意したものとします。</p>
        <p>(Zoom規約)</p>
        <p>Zoom利用規約:<a href="https://explore.zoom.us/ja/terms/">https://explore.zoom.us/ja/terms/</a></p>
        <p>利用ガイドライン:<a href="https://explore.zoom.us/ja/acceptable-use-guidelines/">https://explore.zoom.us/ja/acceptable-use-guidelines/</a></p>
        <p>Zoomプライバシーステートメント:<a href="https://explore.zoom.us/ja/privacy/">https://explore.zoom.us/ja/privacy/</a></p>
        <h2>(本システムの利用条件)</h2>
        <h3>第2条</h3>
        <p>1. 利用者は、本システムを利用頂くために、インターネットへの接続が必要になります。</p>
        <p>2. 利用者のデバイス及びネットワーク環境、またインターネット環境では、状況によって利用者が期待する十分な音声品質が確保できない場合があります。</p>
        <p>3. 本システムの利用にあたって、利用者は、自らの責任と負担にて使用することに同意するものとします。また、利用者が本システムを業務上で利用する場合は、当該事業者も本規約に同意したうえで本システムを利用してください。</p>
        <p>4. 本法人は、本システムの使用又は使用不能によって生じたいかなる損害の責任も負いません。また、本システム及びその修正・改修プログラム、修正・改修アプリケーションその他修正版・改修版のシステムが支障なく若しくは誤作動なく作動することも保証いたしません(なお、修正・改修等があった場合には、当該修正・改修後のものを含めて本システムとみなします。)。</p>
        <p>5. 本法人は、本システムのバグの改善やサポートを行う義務を一切負わないものとします。</p>
        <p>6. 本システムの利用に際してはデータ通信が発生します。通信費は利用者負担となります。</p>
        <h2>(Zoomに関する利用条件)</h2>
        <h3>第3条</h3>
        <p>1. 本システムのうち、Zoom の提供はZoom 社が行います。</p>
        <p>2. Zoom は、インターネットの利用を前提としたサービスです。利用者は Zoom 社が推奨する通信機器及びソフトウェア等の環境を自己の責任において準備することとします。</p>
        <p>3. 通信料は、利用者の負担となります。</p>
        <p>4. 当社は、Zoom の接続及びZoomに関連するソフトウェアの使用方法等のサポートはいたしません。</p>
        <p>5. 当社は、Zoomが常に正常に動作することを保証するものではありません。</p>
        <p>6. 利用者は、利用者の氏名、肖像及び音声が Zoom 上で通信されることについて同意(未成年者の場合は、その保護者の同意を意味します。)したものとみなします。</p>
        <p>7. 利用者は、自己の責任と判断にもとづき Zoom を利用することとします。</p>
        <p>8. 利用者は、Zoom の利用にあたり、常に Zoom 及び OS(オペレーティングシステム)は最新のバージョンを利用するものとします。</p>
        <p>9. 利用者は、Zoom を利用する前に、自己の機器及び利用する通信について、マルウェアへの感染防止や通信の暗号化等必要なセキュリティ対策を行う必要があります。</p>
        <h2>(本システムの終了)</h2>
        <h3>第4条</h3>
        <p>1. 本法人は、本システムを、利用者への通知の有無に関わらず、いつでも終了できるものとします。</p>
        <p>2. 本システムの終了に際し、本法人は、利用者又はいかなる第三者に対しても一切責任を負いません。</p>
        <h2>(知的財産)</h2>
        <h3>第5条</h3>
        <p>本システムに関するすべてのソフトウェア・プログラム、テキスト及びビジュアル等の一切の知的財産権(著作権第27条及び第28条の権利を含みます。)は、本法人又は本法人が別途指示する者が保有します。</p>
        <h2>(プライバシー)</h2>
        <h3>第6条</h3>
        <p>本法人は、利用者のプライバシーを尊重しています。利用者から収集した本システム上の情報を安全に管理するため、セキュリティに最大限の注意を払います。利用者のプライバシー情報及び個人情報は、本法人のプライバシーポリシーに従って適切に取り扱います。また、Zoomの利用においては、Zoom 社のZoomプライバシーステートメントに従って取り扱われます。</p>
        <h2>(緊急通報及び外部発信への非対応)</h2>
        <h3>第7条</h3>
        <p>1. 本システムは、警察機関、海上保安機関、消防機関及び医療機関その他の機関への緊急通報手段を提供するものではありません。</p>
        <p>2. 固定電話や携帯電話への発信手段を提供するものではありません。</p>
        <h2>(広告に関して)</h2>
        <h3>第8条</h3>
        <p>本法人は、本システムに本法人又は本法人が認めた第三者の広告を掲載することがあります。</p>
        <h2>(第三者の委託に基づき本サービスを提供する場合)</h2>
        <h3>第9条</h3>
        <p>第三者(以下「外部運営主体」といいます。)の委託に基づき、本法人が利用者に本サービスを提供する場合、外部運営主体が別途利用者に対し、運用管理規約その他取り決めを行う場合があります。利用者が当該外部運営主体の取り決めに合意した場合、その取り決めは本規約に抵触しない範囲においてその効力を有するものとします。</p>
        <h2>(情報管理)</h2>
        <h3>第10条</h3>
        <p>1.利用者に係る本システム上の情報のバックアップは利用者ご自身で行なってください。本法人は、利用者に係る本システム上の情報のバックアップを行う義務を負いません。</p>
        <p>2.本法人は、本システムを提供するために、本システム上の情報を利用することがあります。この場合、本法人は、本法人が必要かつ適正とみなす範囲で、情報の省略等の情報の変更を加えることがあります。本法人は、本システム上の情報の利用権を本法人と提携する第三者に再承認する場合があります。</p>
        <p>3.本法人は、法令又は本規約の遵守状況などを確認する必要がある場合、本システム上の情報の内容の確認をすることができます。ただし、本法人は確認を行なう義務を負うものではありません。</p>
        <p>4.本法人は、本システム上の情報に関し、法令若しくは本規約に違反し、又は違反するおそれがあると認めた場合その他業務上の必要がある場合、あらかじめ利用者に通知することなく、これらの情報を削除するなどの方法により、本システムでの情報の利用を制限できます。</p>
        <h2>(禁止事項)</h2>
        <h3>第11条</h3>
        <p>利用者は、本システムの利用にあたり、本規約及びZoom規約に反する行為のほか、次に掲げる行為をしてはなりません。</p>
        <p>(1)本システムを通じて取り扱われる他の利用者に係る情報その他本システム上の情報を不正に利用する行為</p>
        <p>(2)本システムを通じて取り扱われる他の利用者に係る情報その他本システム上の情報を改竄する行為</p>
        <p>(3)本システムを通じて取り扱われる他の利用者に係る情報その他本システム上の情報を漏洩させる行為</p>
        <p>(4)他の利用者になりすまして本システムを利用する行為</p>
        <p>(5)本システム上に有害なコンピュータープログラム等を送信又は書き込む行為</p>
        <p>(6)他の利用者の同意を得ることなく又は詐欺的な手段により、他の利用者に係る本システム上の情報を収集する行為</p>
        <p>(7)本システムの利用又は提供を妨げる行為</p>
        <p>(8)本システムを利用した営業活動その他営利を目的とした行為(本法人の事前の同意(Zoomに関してはZoomの事前の同意を更に要する。)がある場合を除く。)</p>
        <p>(9)自己以外の者に本システムを利用させる行為(本法人の事前の同意(Zoomに関してはZoomの事前の同意を更に要する。)がある場合を除く。)</p>
        <p>(10)第三者又は本法人の財産、名誉及びプライバシー等を侵害する行為</p>
        <p>(11)その他本法人が不適切と判断した行為</p>
        <h2>(弁償)</h2>
        <h3>第12条</h3>
        <p>利用者は、利用者が本規約の規定に違反して、本システムに障害を発生させ又は本システム上の情報を漏洩若しくは消去させた場合には、利用者の故意又は過失の程度に応じて修理又は弁償に要した経費を負担しなければなりません。</p>
        <h2>(免責事項)</h2>
        <h3>第13条</h3>
        <p>1. 本法人は、利用者又は利用者の通信機器及びソフトウェア等の環境について一切関与せず、また本システムの利用に起因して、利用者のデバイスに与える影響又は損害について一切の責任を負いません。</p>
        <p>2. 本法人は、利用者間の通信や活動に関与しません。利用者間又は利用者とその他の第三者との間で争いが発生した場合、当該利用者間又は利用者とその他の第三者の間で解決するものとし、本法人は一切の責任を負いません。</p>
        <p>3. 本法人は、本システムの内容変更、中止又は終了によって利用者又はその他の第三者に生じたいかなる損害についても、一切責任を負いません。</p>
        <p>4. 予期せぬ要因で本システムの利用に関して障害が生じた場合でも、本法人は一切責任を負いません。</p>
        <p>5. 本法人は、通信内容の監視・チェック・保存の義務を負いません。</p>
        <p>6. 本法人は、本システムに事実上又は法律上の瑕疵(安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。)がないことを明示的にも黙示的にも保証するものではありません。本法人は、利用者に対して、かかる瑕疵を除去して本システムを提供する義務を負いません。</p>
        <p>7. 本法人は、本システムの利用により利用者又はその他の第三者に生じた一切の損害につき、その賠償義務を負いません。</p>
        <h2>(専属的合意管轄・準拠法)</h2>
        <h3>第14条</h3>
        <p>本システムに起因又は関連して利用者と本法人との間に紛争が生じた場合には、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。また、日本国法を準拠法とします。</p>
        <h2>(連絡方法)</h2>
        <h3>第15条</h3>
        <p>1. 本システムに関する本法人から利用者への連絡は、本法人が運営するウェブサイト内の適宜の場所への掲示その他本法人が適当と判断する方法により行ないます。</p>
        <p>2. 本システムに関する利用者から本法人への連絡は、本法人が運営するウェブサイト内の適宜の場所に設置するお問い合わせフォームの送信又は本法人が指定する方法により行うこととします。</p>
        <h2>(規約の変更)</h2>
        <h3>第16条</h3>
        <p>本法人は、本法人が必要と判断する場合、あらかじめ利用者に通知することなく、いつでも、本規約を変更できるものとします。変更後の本規約は、本法人が運営するウェブサイト内の適宜の場所に掲示された時点からその効力を生じるものとし、利用者は本規約の変更後も本システムを使い続けることにより、変更後の本規約に対する有効かつ取消不能な同意をしたものとみなされます。本システムをご利用の際には、随時、最新の本規約をご参照ください。</p>
        <p>以上</p>
        <p>最終更新日:2023年10月1日</p>
      </div>
    </div>
    <div class="lang-content" v-if="lang == 'en'">
      <h1>Narrative Phone System Terms of Use<a href="#" @click="switchLang" class="switch-lang">日本語</a></h1>
      <h2>(Definition of words)</h2>
      <table class="difinition-table">
        <tbody>
          <tr>
            <td><p>the corporation</p></td>
            <td><p>Cross Care Field Co., Ltd.</p></td>
          </tr>
          <tr>
            <td><p>this system</p></td>
            <td><p>the system provided by Cross Care Field Co., Ltd. under the name "Narrative Phone".</p></td>
          </tr>
          <tr>
            <td><p>user</p></td>
            <td><p>the person using; Narrative Phone system.</p></td>
          </tr>
          <tr>
            <td><p>Zoom</p></td>
            <td>
              <p>Services of Zoom Video Communications, Inc., including the web conferencing service Zoom</p>
            </td>
          </tr>
          <tr>
            <td><p>Zoom Terms</p></td>
            <td>
              <p>All terms and conditions related to the use of Zoom by Zoom Video Communications, Inc., Regardless of its name.</p>
              <p>Examples of websites with terms and conditions are listed below, but they are not limited to these.</p>
              <p>Zoom Terms of Use : <a href="https://explore.zoom.us/ja/terms/">https://explore.zoom.us/ja/terms/</a></p>
              <p>Usage guidelines : <a href="https://explore.zoom.us/ja/acceptable-use-guidelines/">https://explore.zoom.us/ja/acceptable-use-guidelines/</a></p>
              <p>Zoom Privacy Statement : <a href="https://explore.zoom.us/ja/privacy/">https://explore.zoom.us/ja/privacy/</a></p>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Article 1 (Purpose. etc.)</h2>
      <p>These terms and conditions set forth the conditions for the use of the system provided by Cross Care Field Co., Ltd. under the name "Narrative Phone" between the person using this system and this corporation.</p>
      <p>Additionally, this system uses the services of Zoom Video Communications, Inc.</p>
      <p>Hereinafter, when we refer to "use of this system" or "use of this system", unless otherwise specified, this includes the use of Zoom via or using this system.</p>
      <p>Users will need a Zoom account to use this system. When using this system, users must agree to the Zoom Terms and Conditions as well as these Terms. Users should be sure to read these Terms and the Zoom Terms carefully before using this system. Please note that when a user starts using this system, the user is deemed to have agreed to these terms.</p>
      <h2>Article 2 (Terms of use for this system)</h2>
      <ol>
        <li>Users will need an Internet connection to use this system.</li>
        <li>Depending on the user's device, network environment, and internet environment, it may not be possible to ensure sufficient audio quality that the user expects.</li>
        <li>When using this system, users agree to use it at their own responsibility and expense. In addition, if the user uses this system for business purposes, the relevant business operator must also agree to these terms before using this system.</li>
        <li>
          The Corporation is not responsible for any damage caused by the use or inability to use the System. Furthermore, we do not guarantee that this system, its correction/improvement programs, correction/improvement applications, or other corrected/improved versions of the system will operate without problems or malfunctions..<br/>
          (If there is any modification or modification, the system will include the system after the modification or modification.)
        </li>
        <li>The Corporation does not have any obligation to improve bugs in the System or provide support.</li>
        <li>Data communication will occur when using this system. Communication costs will be borne by the user.</li>
      </ol>
      <h2>Article 3 (Terms of use regarding Zoom)</h2>
      <ol>
        <li>Of this system, Zoom is provided by Zoom Video Communications, Inc.</li>
        <li>Zoom is a service that requires the use of the Internet. Users are responsible for preparing the communication equipment and software environment recommended by Zoom Video Communications, Inc.</li>
        <li>Communication charges are the responsibility of the user.</li>
        <li>Our company does not provide support for Zoom connections or how to use Zoom-related software.</li>
        <li>We do not guarantee that Zoom will always work properly.</li>
        <li>The user is deemed to have consented to the user's name, portrait, and voice being communicated on Zoom (in the case of a minor, this means the consent of the user's guardian).</li>
        <li>Users will use Zoom based on their own responsibility and judgment.</li>
        <li>When using Zoom, the user shall always use the latest version of Zoom and his/her OS (operating system).</li>
        <li>Before using Zoom, users must take necessary security measures for their own devices and communications, such as preventing malware infection and encrypting communications.</li>
      </ol>
      <h2>Article 4(Termination of this system)</h2>
      <ol>
        <li>The Corporation may terminate the System at any time with or without notice to the User.</li>
        <li>Upon termination of this system, the corporation will not be liable to the user or any third party.</li>
      </ol>
      <h2>Article 5 (Intellectual property)</h2>
      <p>All intellectual property rights (including the rights under Copyright Articles 27 and 28) relating to this system, including all software programs, texts, and visuals, shall be owned by the Corporation or a person separately designated by the Corporation. I will hold it.</p>
      <h2>Article 6 (Privacy)</h2>
      <p>This corporation respects the privacy of its users. We pay the utmost attention to security in order to safely manage the information collected from users on this system. User privacy information and personal information will be handled appropriately in accordance with the corporation's privacy policy. Additionally, use of Zoom will be handled in accordance with the Zoom Privacy Statement of Zoom Video Communications, Inc.</p>
      <h2>Article 7 (Non-response to emergency calls and external calls)</h2>
      <ol>
        <li>This system does not provide a means of emergency notification to police agencies, coast guard agencies, fire departments, medical institutions, or other organizations.</li>
        <li>It does not provide a means to make calls to landlines or mobile phones.</li>
      </ol>
      <h2>Article 8 (Regarding advertising)</h2>
      <p>The Corporation may post advertisements of the Corporation or third parties approved by the Corporation on this system.</p>
      <h2>Article 9(When providing this service based on commission from a third party)</h2>
      <p>If the Corporation provides this service to users based on the entrustment of a third party (hereinafter referred to as the "external operating entity"), the external operating entity will separately make operational management terms and other arrangements with the users. There may be cases. If the User agrees to an arrangement with the external operating entity, such arrangement shall remain in effect to the extent that it does not conflict with these Terms.</p>
      <h2>Article 10(Information management)</h2>
      <ol>
        <li>Users are responsible for backing up their own information on this system. The Corporation is not obligated to back up information on this system related to users.</li>
        <li>The Corporation may use information on this System in order to provide this System. In this case, the Corporation may make changes to the information, such as omitting information, to the extent that the Corporation deems necessary and appropriate. The Corporation may re-approve the right to use the information on this system to a third party affiliated with the Corporation.</li>
        <li>The Corporation may confirm the content of information on this system if it is necessary to confirm the compliance status of laws and regulations or these Terms. However, the Corporation is not obligated to perform such confirmation.</li>
        <li>If the Corporation deems that information on this system violates or is likely to violate laws or regulations or these Terms of Use, or if there is any other business necessity, the Corporation may dispose of such information without prior notice to users. You can restrict the use of information in this system by deleting the information, etc.</li>
      </ol>
      <h2>Article 11 (Prohibited matter)</h2>
      <p>When using this system, users must not engage in any of the following actions in addition to actions that violate these Terms and Zoom Terms.</p>
      <ol>
        <li>Acts of illegally using information related to other users handled through this system and other information on this system</li>
        <li>Acts of falsifying information related to other users handled through this system and other information on this system</li>
        <li>Acts of leaking information related to other users handled through this system and other information on this system</li>
        <li>Acts of using this system by impersonating another user</li>
        <li>Acts of transmitting or writing harmful computer programs, etc. onto this system.</li>
        <li>Acts of collecting information on this system related to other users without obtaining the consent of other users or by fraudulent means</li>
        <li>Acts that hinder the use or provision of this system</li>
        <li>Business activities or other commercial activities using this system (excluding cases with the prior consent of the Corporation (in the case of Zoom, Zoom's prior consent is further required))</li>
        <li>Acts of allowing someone other than yourself to use this system (excluding cases with the prior consent of the Corporation (for Zoom, prior consent of Zoom is additionally required))</li>
        <li>Acts that infringe on the property, honor, privacy, etc. of a third party or this corporation</li>
        <li>Other acts that the Corporation deems inappropriate.</li>
      </ol>
      <h2>Article 12 (Compensation)</h2>
      <p>If the User violates the provisions of these Terms and causes a failure in the System or leaks or erases information on the System, the User shall be liable for liability depending on the degree of intentionality or negligence on the part of the User. You must bear the cost of repair or compensation.</p>
      <h2>Article 13 (Disclaimer)</h2>
      <ol>
        <li>The Corporation does not have any involvement in the environment of users or their communication equipment, software, etc., and assumes no responsibility for any impact or damage to users' devices due to the use of this system. .</li>
        <li>The Corporation is not involved in communications or activities between users. If a dispute arises between users or between a user and another third party, it shall be resolved between the users or between the user and the other third party, and the Corporation shall not be responsible for any. I am not responsible.</li>
        <li>The Corporation assumes no responsibility for any damage caused to users or other third parties due to content changes, suspension, or termination of the System.</li>
        <li>The Corporation will not be held responsible for any failures in the use of this system due to unforeseen factors.</li>
        <li>The Corporation is not obligated to monitor, check, or store communication content.</li>
        <li>The Corporation shall not be liable for any factual or legal defects in the System (defects related to safety, reliability, accuracy, completeness, effectiveness, suitability for a particular purpose, security, etc., errors, bugs, infringement of rights, etc.). ) is not guaranteed, either expressly or implicitly. The Corporation has no obligation to the User to remove such defects and provide the System.</li>
        <li>The Corporation is not obligated to compensate for any damage caused to users or other third parties due to the use of this system.</li>
      </ol>
      <h2>Article 14 (Exclusive jurisdiction/governing law)</h2>
      <p>If a dispute arises between a user and this corporation arising from or related to this system, the Tokyo District Court or Tokyo Summary Court shall have exclusive jurisdiction of the first instance. In addition, the governing law shall be the laws of Japan.</p>
      <h2>Article 15(Contact method)</h2>
      <ol>
        <li>Communications from the Corporation to users regarding this system will be made by posting in appropriate locations on the website operated by the Corporation or by any other method deemed appropriate by the Corporation.</li>
        <li>Users may contact the Corporation regarding this system by sending an inquiry form located at an appropriate location on the website operated by the Corporation, or by a method designated by the Corporation.</li>
      </ol>
      <h2>Article 16 (Changes to terms)</h2>
      <p>The Corporation may change these Terms at any time without prior notice to users if the Corporation deems it necessary. The revised Terms of Use shall become effective from the time they are posted at an appropriate location on the website operated by the Corporation, and users will not be able to accept the changes by continuing to use this system even after the revised Terms of Use. You will be deemed to have given your valid and irrevocable consent to the subsequent Terms of Use. When using this system, please refer to the latest version of these Terms from time to time.</p>
      <p>That is all.</p>
      <p>Last updated : October 1, 2023</p>
    </div>
  </div>
</template>

<script>
export default{
  name: 'TermOfUse',

  props: {
    lang: String
  },

  methods: {
    switchLang () {
      this.$emit('switchLangEvent')
    }
  }
}
</script>

<style lang="scss" scoped>
$breakpoints: (
  "phone": "screen and (max-width: 420px)",
  "tablet": "screen and (max-width: 1023px)",
  "pc": "screen and (min-width: 1024px)"
);

// メディアクエリ
@mixin mediaQuery($breakpoint: sp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

.term-of-use {
  background-color: #fff;
  h1 {
    margin: 20px 0;
    font-size: 1.2em;
    font-weight: bold;
    color: #000;
    text-align: center;
    a {
      display: inline-block;
      margin-left: 20px;
      font-size: 14px;
    }
  }
  h2 {
    margin: 20px 0 10px;
    font-size: 0.9em;
    font-weight: bold;
    color: #000;
  }
  h3 {
    margin: 10px 0;
    font-size: 0.9em;
    font-weight: bold;
    color: #000;
  }
  p {
    margin: 3px 0;
    font-size: 0.9em;
  }
  ol {
    li {
      margin: 3px;
      font-size: 0.9em;
    }
  }
  table {
    width: 100%;
    border: 1px solid #aaa;
    border-collapse: collapse;
    td {
      padding: 10px;
      border: 1px solid #aaa;
      vertical-align: top;
    }
    .title {
      padding-right: 5px;
      white-space: nowrap;
      @include mediaQuery('phone') {
        width: 50px;
      }
    }
    .content {
      margin: 0;
      @include mediaQuery('phone') {
        width: calc(100% - 44px - 50px);
      }
    }
  }
}

</style>