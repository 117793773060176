<template>
  <div class="background">
    <div class="container">
      <router-view/>
    </div>
  </div>
</template>

<script>
import 'normalize.css'

export default {
  name: 'App'
}
</script>

<style lang="scss">
@import 'assets/css/global.scss';

$breakpoints: (
  "phone": "screen and (max-width: 420px)",
  "tablet": "screen and (max-width: 1023px)",
  "pc": "screen and (min-width: 1024px)"
);

// メディアクエリ
@mixin mediaQuery($breakpoint: sp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
// カラーテーブル
$nb-normal-color: #692220;
$nb-member-color: #2b4733;
$nb-reporter-color: #4f455c;

$normal-btn-color: #666;
$disable-btn-color: #aaa;
$normal-btn-text-color: #fff;

$success-color: #5cb85c;
$warning-color: #ee7800;
$danger-color: #b94047;

html {
  word-break: break-all;
  font-family: helvetica, Arial, Roboto, "Droid Sans", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Osaka, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 16px;
}

#app {
  height: 100%;
  position: relative;
}

.background {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  // word-break: break-all;
  // font-family: helvetica, Arial, Roboto, "Droid Sans", "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Osaka, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // color: #333;
  // font-size: 14px;
}

.container {
  height: 100%;
  width: 1000px;
  margin: 0 auto;
  background-color: #fff;
  overflow: auto;
  @include mediaQuery('tablet') {
    width: 100%;
  }
  @include mediaQuery('phone') {
    width: 100%;
  }
}

// hide google reCAPTCHA badge.
.grecaptcha-badge { visibility: hidden; }
</style>
