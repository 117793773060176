<template>
  <div class="main">
    <div class="title-block" v-if="resetOldRelation">
      <img class="title-icon" src="/images/hero-logotype.png" />
      <h1><span>ナラティブフォンへ</span><span>ようこそ！</span></h1>
      <div class="caption">
        通話アプリ「ナラティブフォン」へようこそ。<br/>
        ナラティブフォンを利用いただくためには、最初にナラティブフォンのアカウントとデバイスを登録する必要があります。<br/>
        この手順には、いくつかの方法とステップがあり、順に行っていただくことでナラティブフォンをご利用いただけるようになります。
      </div>
      <div class="notify" v-if="firstParamsFailed">
        必要なパラメータが与えられていません。
      </div>
    </div>
    <div class="title-block" v-if="!resetOldRelation">
      <img class="title-icon" src="/images/hero-logotype.png" />
      <h1><span>ナラティブフォン</span><span>アカウントの追加</span></h1>
      <div class="caption">
        ナラティブフォンにアカウントを追加する方法を選択してください。
      </div>
      <div class="notify" v-if="firstParamsFailed">
        必要なパラメータが与えられていません。
      </div>
    </div>
    <create-user-confirm-rule
      v-if="currentPage === 'acceptRule'"
      v-on:moveNextEvent="moveToMethodSelect"
      v-on:needToAcceptEvent="showAcceptNotify"
    />
    <create-user-select-create-method
      v-if="currentPage === 'methodSelection'"
      v-on:createWithNarrativebookSSOEvent="createWithNarrativebookSSO"
      v-on:createWithIdAndPasswordEvent="createWithIdAndPassword"
      v-on:importWithNarrativebookSSOEvent="importWithNarrativebookSSO"
      v-on:importWithIdAndPasswordEvent="importWithIdAndPassword"
    />
    <create-user-input-user-info
      v-if="currentPage === 'inputUserInfo'"
      v-on:mailValidationNotifyEvent="mailValidationNotify"
      v-on:validationMaildSentEvent="validationMaildSent"
      v-on:submitEvent="userInputSubmit"
    />
    <import-user-with-signin
      v-if="currentPage === 'signinForImport'"
      v-on:signinSucceedEvent="signinSucceed"
    />
    <create-user-finished
      v-if="currentPage === 'finished'"
      :result="userCreateResult"
    />
    <confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
    />
  </div>
</template>

<script>
import { User, PhoneDevice } from '@/api' 

import CreateUserConfirmRule from '@/components/createUser/CreateUserConfirmRule.vue'
import CreateUserSelectCreateMethod from '@/components/createUser/CreateUserSelectCreateMethod.vue'
import CreateUserInputUserInfo from '@/components/createUser/CreateUserInputUserInfo.vue'
import ImportUserWithSignin from '@/components/createUser/ImportUserWithSignin.vue'
import CreateUserFinished from '@/components/createUser/CreateUserFinished.vue'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

import ConfirmDialogControll from '@/mixin/ConfirmDialogControll.js'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default{
  mixins: [ConfirmDialogControll],

  name: 'NewUserView',

  components: {
    ConfirmDialog,
    CreateUserConfirmRule,
    CreateUserSelectCreateMethod,
    CreateUserInputUserInfo,
    ImportUserWithSignin,
    CreateUserFinished
  },

  data () {
    return {
      currentPage: 'acceptRule',
      phoneDeviceToken: '',
      phoneDeviceDescription: '',
      pushTarget: -1,
      firstParamsFailed: false,
      acceptRule: false,
      userCreateResult: {},
      resetOldRelation: false
    }
  },

  // created () {
  //   this.$native.appSendWebSetNative = status => {
  //     this.$native.status = status
  //   }
  // },

  watch: {
    '$route': { handler: 'storeQuery', immediate: true }
  },

  methods: {
    storeQuery () {
      if (this.$route.query.phone_device_description) {
        this.phoneDeviceDescription = this.$route.query.phone_device_description
      }
      if (this.$route.query.phone_device_token) {
        this.phoneDeviceToken = this.$route.query.phone_device_token
      }
      if (this.$route.query.push_target) {
        this.pushTarget = this.$route.query.push_target
      }
      if (this.$route.query.reset_old_relation && this.$route.query.reset_old_relation == 'YES') {
        this.resetOldRelation = true
      } else {
        // reset_old_relationがないということは、2回め以降のアクセスであるため、規約同意ページは省略する
        this.acceptRule = true
        this.currentPage = 'methodSelection'
      }
      if (this.phoneDeviceDescription.length <= 0 || this.phoneDeviceToken.length <= 0 || this.pushTarget < 0) {
        this.firstParamsFailed = true
      }
    },

    showAcceptNotify () {
      this.showNotifyDialog("同意が必要です", "アプリを利用するためには、利用規約への同意が必要です。")
    },

    moveToMethodSelect (accept) {
      this.acceptRule = accept
      if (this.acceptRule) {
        this.currentPage = 'methodSelection'
      }
    },

    createWithNarrativebookSSO () {
      console.log('new nb sso')
      var nbAuthURL = location.protocol 
        + '//' 
        + location.host 
        + '/api/user/contact_narrative_auth' 
        + '?phone_device_token=' + this.phoneDeviceToken
        + '&phone_device_description=' + this.phoneDeviceDescription
        + '&push_target=' + this.pushTarget
      if (this.resetOldRelation) {
        nbAuthURL += '&reset_old_relation=YES'
      }
      this.showConfirmDialog(
        'ナラティブブックアカウントから作成', 
        'ナラティブブックの認証を経て、利用可能な可能なデータを取得します。', 
        DIALOG_TYPE.EXECUTE, 
        () => {
          console.log('narrative auth url: ' + nbAuthURL)
          window.location.href = nbAuthURL
        }
      )
    },

    createWithIdAndPassword () {
      console.log('new id pass')
      this.currentPage = 'inputUserInfo'
    },

    importWithNarrativebookSSO () {
      console.log('import nb sso')
      var nbAuthURL = location.protocol 
        + '//' 
        + location.host 
        + '/api/user/contact_narrative_auth' 
        + '?phone_device_token=' + this.phoneDeviceToken
        + '&phone_device_description=' + this.phoneDeviceDescription
        + '&push_target=' + this.pushTarget
        + '&select_from_exist_users=YES'
      if (this.resetOldRelation) {
        nbAuthURL += '&reset_old_relation=YES'
      }
      this.showConfirmDialog(
        'ナラティブブックアカウントからインポート', 
        'ナラティブブックの認証を経て、インポート可能なデータを取得します。', 
        DIALOG_TYPE.EXECUTE, 
        () => {
          console.log('narrative auth url: ' + nbAuthURL)
          window.location.href = nbAuthURL
        }
      )
    },

    importWithIdAndPassword () {
      console.log('import id pass')
      this.currentPage = 'signinForImport'
    },

    validationMaildSent (email) {
      this.showConfirmDialog(
        '認証メールを送信しました',
        'メールアドレスの認証のために、' + email + '宛にメールを送信しました。\nメール本文に書かれている8桁の認証番号を入力してください。',
        DIALOG_TYPE.CONFIRM,
        () => {}
      )
    },

    mailValidationNotify (title) {
      this.showNotifyDialog(title, '')
    },

    userInputSubmit (formData) {
      formData.append('phone_device[phone_device_description]', this.phoneDeviceDescription)
      formData.append('phone_device[phone_device_token]', this.phoneDeviceToken)
      formData.append('phone_device[push_target]', this.pushTarget)
      if (this.resetOldRelation) {
        formData.append('reset_old_relation', 'YES')
      }
      User.createWithDevice({ formData: formData }).then(res => {
        console.log(res)
        var responseData = {
          user_id: res.data.user.id,
          user_name: res.data.user.name,
          user_account_name: res.data.user.account_name,
          user_email: res.data.user.email,
          narrative_phone_id: res.data.user.narrative_phone_id,
          ios_zoom_available: res.data.user.ios_zoom_available,
          // ios_zoom_user_id: res.data.user.ios_zoom_user_id,
          // ios_zoom_user_email: res.data.user.ios_zoom_user_email,
          // ios_zoom_user_first_name: res.data.user.ios_zoom_user_first_name,
          // ios_zoom_user_last_name: res.data.user.ios_zoom_user_last_name,
          // ios_zoom_authorization_code: res.data.user.ios_zoom_authorization_code,
          // ios_zoom_access_token: res.data.user.ios_zoom_access_token,
          // ios_zoom_refresh_token: res.data.user.ios_zoom_refresh_token,
          android_zoom_available: res.data.user.android_zoom_available,
          // android_zoom_user_id: res.data.user.android_zoom_user_id,
          // android_zoom_user_email: res.data.user.android_zoom_user_email,
          // android_zoom_user_first_name: res.data.user.android_zoom_user_first_name,
          // android_zoom_user_last_name: res.data.user.android_zoom_user_last_name,
          // android_zoom_authorization_code: res.data.user.android_zoom_authorization_code,
          // android_zoom_access_token: res.data.user.android_zoom_access_token,
          // android_zoom_refresh_token: res.data.user.android_zoom_refresh_token,
          phone_device_id: res.data.phone_device.phone_device_id,
          phone_device_target: res.data.phone_device.push_target,
          phone_device_authenticate_token: res.data.phone_device.device_authenticate_token
        }
        if (this.$native) {
          this.$native.event('userWithDeviceCreatedEvent', responseData)
        }
        this.userCreateResult = responseData
        this.currentPage = 'finished'
      }).catch(err => {
        console.error(err)
        if (this.$native) {
          this.$native.event('userWithDeviceCreateFailedEvent')
        }
      })
    },

    signinSucceed () {
      // post phone_device
      var formData = new FormData()
      formData.append('phone_device[phone_device_description]', this.phoneDeviceDescription)
      formData.append('phone_device[phone_device_token]', this.phoneDeviceToken)
      formData.append('phone_device[push_target]', this.pushTarget)
      if (this.resetOldRelation) {
        formData.append('reset_old_relation', 'YES')
      }
      PhoneDevice.create({ formData: formData }).then(res => {
        console.log(res)
        var responseData = {
          user_id: res.data.user.id,
          user_name: res.data.user.name,
          user_account_name: res.data.user.account_name,
          user_email: res.data.user.email,
          narrative_phone_id: res.data.user.narrative_phone_id,
          ios_zoom_available: res.data.user.ios_zoom_available,
          // ios_zoom_user_id: res.data.user.ios_zoom_user_id,
          // ios_zoom_user_email: res.data.user.ios_zoom_user_email,
          // ios_zoom_user_first_name: res.data.user.ios_zoom_user_first_name,
          // ios_zoom_user_last_name: res.data.user.ios_zoom_user_last_name,
          // ios_zoom_authorization_code: res.data.user.ios_zoom_authorization_code,
          // ios_zoom_access_token: res.data.user.ios_zoom_access_token,
          // ios_zoom_refresh_token: res.data.user.ios_zoom_refresh_token,
          android_zoom_available: res.data.user.android_zoom_available,
          // android_zoom_user_id: res.data.user.android_zoom_user_id,
          // android_zoom_user_email: res.data.user.android_zoom_user_email,
          // android_zoom_user_first_name: res.data.user.android_zoom_user_first_name,
          // android_zoom_user_last_name: res.data.user.android_zoom_user_last_name,
          // android_zoom_authorization_code: res.data.user.android_zoom_authorization_code,
          // android_zoom_access_token: res.data.user.android_zoom_access_token,
          // android_zoom_refresh_token: res.data.user.android_zoom_refresh_token,
          phone_device_id: res.data.phone_device.phone_device_id,
          phone_device_target: res.data.phone_device.push_target,
          phone_device_authenticate_token: res.data.phone_device.device_authenticate_token
        }
        if (this.$native) {
          this.$native.event('deviceCreatedEvent', responseData)
        }
        this.userCreateResult = responseData
        this.currentPage = 'finished'
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog('デバイスの登録に失敗しました', err.response.data.message )
        if (this.$native) {
          this.$native.event('deviceCreateFailedEvent')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  padding: 20px;
  span {
    display: inline-block;
  }
  .title-block {
    margin: 10px;
    text-align: center;
    img {
      max-width: 100%;
    }
    h1 {
      text-align: center;
      font-size: 1.5rem;
      font-weight: normal;
    }
    .caption {
      font-size: 1rem;
      line-height: 1.3rem;
    }
    .notify {
      margin: 10px;
      padding: 10px;
      border: 1px solid #f00;
      color: #f00;
    }
  }
  .new-user-create-selection {
    margin: 10px;
    padding: 10px;
    border: 1px solid #aaa;
    cursor: pointer;
    .title {
      margin-bottom: 10px;
      font-size: 1.1rem;
      font-weight: bold;
    }
    .description {
      line-height: 1.4rem;
    }
  }
}
</style>
