<template>
  <div class="confirm-rule">
    <div class="rule-display-column">
      <term-of-use
        :lang="lang"
        v-on:switchLangEvent="switchLang" />
    </div>
    <div class="confirmation" @click="toggleAccept">
      <custom-check-box
        :type="'small'"
        :active="accept"
        :enabled="true"
      />
      <span>規約に同意</span>
    </div>
    <div class="controll">
      <button class="accept-btn" :class="{enable: accept}" @click="moveNext">次へ</button>
    </div>
  </div>
</template>

<script>
import TermOfUse from '../common/TermOfUse.vue'
import CustomCheckBox from '../common/CustomCheckBox.vue'

export default{
  name: 'CreateUserSelectCreateMethod',

  components: {
    TermOfUse,
    CustomCheckBox
  },

  data () {
    return {
      lang: 'jp',
      accept: false
    }
  },

  mounted () {
    this.initValue()
  },

  methods: {
    initValue () {
      this.accept = false
    },

    switchLang () {
      if (this.lang === 'jp') {
        this.lang = 'en'
      } else {
        this.lang = 'jp'
      }
    },

    toggleAccept () {
      this.accept = !this.accept
    },

    moveNext () {
      if (this.accept) {
        this.$emit('moveNextEvent', this.accept)
      } else {
        this.$emit('needToAcceptEvent')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/colorTable.scss';
.confirm-rule {
  .rule-display-column {
    height: 200px;
    overflow: auto;
    padding: 10px;
    border: 1px solid #aaa;
  }
  .confirmation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    span {
      font-weight: bold;
      font-size: 1.1rem;
    }
  }
  .controll {
    text-align: center;
    .accept-btn {
      padding: 5px 10px;
      width: 100px;
      font-weight: bold;
      font-size: 1.1rem;
      border: none;
      border-radius: 5px;
      color: #fff;
      background-color: $phone-main-desable-color;
      &.enable {
        background-color: $phone-main-color;
      }
    }
  }
}
</style>