<template>
  <div class="main">
    <div class="title">
      <h1>ナラティブフォン管理コンソール</h1>
    </div>
    <div class="signin-block">
      <form id='own_signin_form' v-on:submit.prevent="doSignin">
        <div class="signin-input"><label>ログインID</label><input type="text" v-model="inputLoginId" ref="loginIDField"></div>
        <div class="signin-input"><label>パスワード</label><input type="password" ref="passwordField" id="password" v-model="inputPassword"></div>
        <div class="signin_pass_toggle">パスワード表示<input type="checkbox" ref="toggle" v-on:change="togglePasswordVisible" /></div>
        <button type="submit" class="btn success signin-btn">ログイン</button>
      </form>
    </div>
    <div class="signin-button-controlls">
      パスワードを忘れた場合は<a href="/reset_password">こちら</a>
      <br>
    </div>
  </div>
</template>

<script>
import { SystemAdmin } from '@/api' 

export default{
  name: 'TouthLandingView',

  data() {
    return {
      inputLoginId: '',
      inputPassword: ''
    }
  },

  methods: {
    togglePasswordVisible (event) {
      const checkbox = event.target
      const passwordField = this.$refs.passwordField
      if (checkbox.checked === true) {
        passwordField.type = 'text'
      } else {
        passwordField.type = 'password'
      }
    },

    doSignin () {
      SystemAdmin.signin({ loginID: this.inputLoginId, password: this.inputPassword }).then(res => {
        console.log(res)
      }).catch(err => {
        console.error(err)
        console.error(err.response)
      })
    }
  }
}
</script>

<sytle lang="scss" scope>

</sytle>