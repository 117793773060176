<template>
  <div class="user-config-home-view">
    <div class="header">
      <img class="long-logo" src="/images/small_line_logo.png" />
      <img class="small-logo" src="/images/narrative_phone_logo.svg" />
      <div class="signin-state" v-if="user">
        <span class="signin-name">{{  user.name }}</span>
        <button class="signout-btn" @click="signout">ログアウト</button>
      </div>
    </div>
    <div class="content-block" v-if="user">
      <h2>ユーザー設定詳細</h2>
      <h3>基本登録情報</h3>
      <div class="line-block">
        <div class="line-items">
          <div class="title">表示名</div>
          <div class="value">{{ user.name }}</div>
        </div>
        <div class="line-items">
          <div class="title">詳細</div>
          <div class="value">{{ user.description }}</div>
        </div>
        <div class="line-items">
          <div class="title">ログインID</div>
          <div class="value">{{ user.account_name }}</div>
        </div>
        <div class="line-items">
          <div class="title">メールアドレス</div>
          <div class="value">{{ user.email }}</div>
        </div>
        <div class="line-items">
          <div class="title">ナラティブフォンID</div>
          <div class="value">{{ user.narrative_phone_id }}</div>
        </div>
        <div class="line-items">
          <div class="title"></div>
          <div class="value">{{ user.icon_img_path }}</div>
        </div>
        <div class="line-items">
          <div class="title">状態</div>
          <div class="value">{{ user.state_jp }}</div>
        </div>
        <div class="controll">
          <button class="change-password-btn" v-if="user.own_signin" @click="changePassword">パスワード変更</button>
          <button class="edit-btn" @click="editUser">編集</button>
          <button class="destroy-btn" @click="deleteUser">削除</button>
        </div>
      </div>
      <h3>ナラティブブック連携情報</h3>
      <div class="line-block" v-if=" user.nb_id && user.nb_id.length > 0">
        <div class="line-items">
          <div class="title">NBID</div>
          <div class="value">{{ user.nb_id }}</div>
        </div>
        <div class="line-items">
          <div class="title"><span>ナラティブブック</span><span>AuthorID</span></div>
          <div class="value">{{ user.nb_author_id }}</div>
        </div>
        <div class="line-items">
          <div class="title"><span>ナラティブブック</span><span>施設ID</span></div>
          <div class="value">{{ user.nb_organization_id }}</div>
        </div>
        <div class="line-items" v-if="narrativeBookAccount">
          <div class="title"><span>ナラティブブック取得情報</span></div>
          <div class="value">{{  narrativeBookAccount }}</div>
        </div>
        <div class="controll">
          <button class="reconnect-narrativebook-btn" @click="reconnectNarrativebook">ナラティブブック再接続</button>
        </div>
      </div>
      <div class="line-block" v-if=" !user.nb_id || user.nb_id.length <= 0">
        ナラティブブック連携情報はありません。
      </div>
      <h3>iOSアプリのZoom連携情報</h3>
      <div class="line-block" v-if="zoomIOSReady">
        <div class="line-items">
          <div class="title">Zoom登録メールアドレス</div>
          <div class="value">{{ user.ios_zoom_user_email }}</div>
        </div>
        <div class="line-items">
          <div class="title">Zoom登録氏名</div>
          <div class="value">{{ zoomNameIOS }}</div>
        </div>
        <div class="controll">
          <button class="edit-btn" @click="reconnectZoomIOS">再接続</button>
        </div>
      </div>
      <div class="line-block" v-if="!zoomIOSReady">
        iOSアプリでのZoom連携情報はありません。
        <div class="controll">
          <button class="edit-btn" @click="reconnectZoomIOS">接続</button>
        </div>
      </div>
      <h3>AndroidアプリのZoom連携情報</h3>
      <div class="line-block" v-if="zoomAndroidReady">
        <div class="line-items">
          <div class="title">Zoom登録メールアドレス</div>
          <div class="value">{{ user.android_zoom_user_email }}</div>
        </div>
        <div class="line-items">
          <div class="title">Zoom登録氏名</div>
          <div class="value">{{ zoomNameAndroid }}</div>
        </div>
        <div class="controll">
          <button class="edit-btn" @click="reconnectZoomAndroid">再接続</button>
        </div>
      </div>
      <div class="line-block" v-if="!zoomAndroidReady">
        AndroidアプリのZoom連携情報はありません。
        <div class="controll">
          <button class="edit-btn" @click="reconnectZoomAndroid">接続</button>
        </div>
      </div>
      <h3>Zoom連携情報の削除</h3>
      <div class="line-block" v-if="zoomIOSReady || zoomAndroidReady">
        <div class="controll">
          <button class="destroy-btn" @click="revokeZoom">Zoomとの連携解除</button>
        </div>
      </div>
    </div>
    <div class="content-block">
      <h2>登録済みデバイス</h2>
      <div class="line-block" v-for="device in devices" :key="device.phone_device_id">
        <div class="line-items">
          <div class="title">デバイス情報</div>
          <div class="value">{{ device.phone_device_description }}</div>
        </div>
        <div class="line-items">
          <div class="title">デバイスID</div>
          <div class="value">{{ device.phone_device_id }}</div>
        </div>
        <div class="line-items">
          <div class="title">Pushサーバー種別</div>
          <div class="value">{{ pushServerStr(device) }}</div>
        </div>
        <div class="line-items">
          <div class="title">作成日時</div>
          <div class="value">{{ device.created_at }}</div>
        </div>
        <div class="controll">
          <button class="destroy-btn" @click="deleteDevice(device)">関連削除</button>
        </div>
      </div>
    </div>
    <div class="content-block">
      <h2>アドレス帳情報</h2>
      <div class="line-block" v-for="category in addressCategories" :key="'ac' + category.id">
        <div class="line-items">
          <div class="title">カテゴリ名</div>
          <div class="value">{{ category.name }}</div>
        </div>
        <div class="line-block" v-for="entry in category.address_entries" :key="'ac_' + category.id + 'ae_' + entry.id">
          <div class="line-items">
            <div class="title">名前</div>
            <div class="value">{{ entry.name }}</div>
          </div>
          <div class="line-items">
            <div class="title">ナラティブフォンID</div>
            <div class="value">
              {{ entry.narrative_phone_id }}
              <span class="universal-link-target" @click="toggleAttendee(entry.narrative_phone_id)">
                <custom-check-box
                  :type="'small'"
                  :active="attendeeIncluded(entry.narrative_phone_id)"
                  :enabled="true"
                />
                ユニバーサルリンクの通話先
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-block">
      <h2>ユニバーサルリンクテスト</h2>
      <div class="line-item">
        <div class="title">コールバックURL</div>
        <div class="value"><input type="text" v-model="callbackUrl"/></div>
      </div>
      <button class="create-universal-link-btn" @click="createUniversalLink">ユニバーサルリンク生成</button>
      <div class="line-item">
        <div class="title">ユニバーサルリンク</div>
        <div class="value">{{ universalLinkURL }}</div>
      </div>
      <div class="line-item">
        <a :href="universalLinkURL" target="_blank">ユニバーサルリンクにアクセス</a>
      </div>
    </div>
    <edit-user-modal
      :modalDisplay="editModalDisplay"
      :user="user"
      v-on:sendMailValidationEvent="sendMailValidation"
      v-on:cancelEditEvent="cancelEdit"
      v-on:updateEvent="update" />
    <edit-password-modal
      :modalDisplay="passwordModalDisplay"
      v-on:cancelEvent="cancelPasswordChange"
      v-on:updateEvent="updatePassword"
      />
    <confirm-dialog
      :showDialog="confirmDialogVisible"
      :title="confirmDialogTitle"
      :message="confirmDialogMessage"
      :dialogType="confirmDialogType"
      v-on:negativeResultEvent="confirmFinishNegative"
      v-on:positiveResultEvent="confirmFinishPositive"
    />
  </div>
</template>

<script>
import { User, PhoneDevice, AddressCategory } from '@/api'
import EditUserModal from '@/components/createUser/EditUserModal.vue'
import EditPasswordModal from '@/components/createUser/EditPasswordModal.vue'
import CustomCheckBox from '@/components/common/CustomCheckBox.vue'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

import ConfirmDialogControll from '@/mixin/ConfirmDialogControll'

import * as DIALOG_TYPE from '@/assets/constants/dialogType.js'

export default {
  mixins: [ConfirmDialogControll],

  name: 'UserConfigHomeView',

  components: {
    EditUserModal,
    EditPasswordModal,
    CustomCheckBox,
    ConfirmDialog
  },

  data () {
    return {
      user: null,
      narrativeBookAccount: null,
      devices: [],
      editModalDisplay: false,
      passwordModalDisplay: false,
      addressCategories: [],
      callbackUrl: '',
      attendees: [],
      universalLinkURL: ''
    }
  },

  mounted () {
    this.loadUser()
  },

  computed: {
    zoomIOSReady () {
      if (
        this.user && 
        this.user.ios_zoom_available
      ) {
        return true
      }
      return false
    },

    zoomAndroidReady () {
      if (
        this.user && 
        this.user.android_zoom_available
      ) {
        return true
      }
      return false
    },

    zoomNameIOS () {
      // if (
      //   this.user.ios_zoom_user_first_name && 
      //   this.user.ios_zoom_user_last_name && 
      //   this.user.ios_zoom_user_first_name.length > 0  && 
      //   this.user.ios_zoom_user_last_name.length > 0
      // ) {
      //   return this.user.ios_zoom_user_first_name + ' ' + this.user.ios_zoom_user_last_name
      // } else {
      //   return '-----'
      // }
      return '接続情報あり'
    },

    zoomNameAndroid () {
      // if (
      //   this.user.android_zoom_user_first_name && 
      //   this.user.android_zoom_user_last_name && 
      //   this.user.android_zoom_user_first_name.length > 0 && 
      //   this.user.android_zoom_user_last_name.length > 0
      // ) {
      //   return this.user.android_zoom_user_first_name + ' ' + this.user.android_zoom_user_last_name
      // } else {
      //   return '-----'
      // }
      return '接続情報あり'
    }
  },

  methods: {
    loadUser () {
      User.fetch().then(res => {
        console.log(res)
        this.user = res.data.user
        User.fetch_narrativebook_account().then(res => {
          console.log(res)
          this.narrativeBookAccount = res.data.narrative_book_account
        })
        PhoneDevice.fetch().then(res => {
          console.log(res)
          this.devices = res.data.phone_devices
        }).catch(err => {
          console.error(err)
        })
        AddressCategory.fetch().then(res => {
          console.log(res)
          this.addressCategories = res.data.address_categories
        }).catch(err => {
          console.error(err)
        })
      }).catch(err => {
        console.log(err)
      })
    },

    signout () {
      User.signout().then(() => {
        this.user = null
        this.$router.push('/user_config')
      })
    },

    pushServerStr(device) {
      if (device.push_target == 0) {
        return 'APNs'
      } else if (device.push_target == 1) {
        return 'Firebase'
      } else {
        return '-----'
      }
    },

    editUser () {
      this.editModalDisplay = true
    },

    cancelEdit () {
      this.editModalDisplay = false
    },

    sendMailValidation (email) {
      console.log('send mail validation')
      User.sendMailValidation({ email: email })
    },

    update (editUser, token) {
      var formData = new FormData()
      formData.append('user[name]', editUser.name)
      formData.append('user[description]', editUser.description)
      if (editUser.email && editUser.email.length > 0) {
        formData.append('user[email]', editUser.email)
        formData.append('mail_validate_token', token)
      }
      User.update({ formData: formData }).then(res => {
        console.log(res)
        this.loadUser()
        this.editModalDisplay = false
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog('更新失敗', '情報の更新に失敗しました。')
      })
    },

    changePassword () {
      this.passwordModalDisplay = true
    },

    cancelPasswordChange () {
      this.passwordModalDisplay = false
    },

    updatePassword (password, confirmation) {
      User.updatePassword({ password: password, passwordConfirmation: confirmation }).then(() =>{
        this.passwordModalDisplay = false
        this.showNotifyDialog('パスワード変更', 'パスワードが変更されました。')
      }).catch(err => {
        console.error(err)
        this.passwordModalDisplay = false
        this.showNotifyDialog('パスワード変更失敗', 'パスワードの変更に失敗しました。')
      })
    },

    deleteUser () {
      this.showConfirmDialog(
        'ユーザーアカウントの削除', 
        'ユーザーアカウントを削除します。アカウント削除後は強制的にログアウトします。\nこの操作は戻すことができません。\n本当に削除してよろしいですか？', 
        DIALOG_TYPE.EXECUTE, 
        () => {this.executeDeleteUser()}
      )
    },

    executeDeleteUser () {
      User.destroy().then(() => {
        this.showConfirmDialog(
          'ユーザー削除', 
          'ユーザーが削除されました。', 
          DIALOG_TYPE.NOTIFY, 
          () => {
            this.user = null
            this.devices = []
            this.$router.push('/user_config')
          }
        )
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog('ユーザー削除失敗', 'ユーザーの削除に失敗しました。')
      })
    },

    reconnectNarrativebook () {
      User.update_narrativebook_account().then(res => {
        console.log(res)
        this.narrativeBookAccount = res.data.narrative_book_account
      }).catch(err => {
        console.error(err)
      })
    },

    deleteDevice (device) {
      this.showConfirmDialog(
        '登録済みデバイスの関連を削除します。', 
        '登録済みデバイスと、このユーザーの関連を削除します。デバイス情報そのものは削除されません。\nまた、アプリではここで削除された関連が通達されないので、アプリ側で操作不能になる可能性があります。\n関連を戻すためには、アプリでユーザーをインポートすることで復元されます。\nこの操作は戻すことができません。\n本当に削除してよろしいですか？', 
        DIALOG_TYPE.EXECUTE, 
        () => {this.executeDeleteDevice(device)}
      )
    },

    executeDeleteDevice (device) {
      User.delete_device_relation({ target_device_id: device.phone_device_id }).then(res => {
        console.log(res)
        this.devices = res.data.phone_devices
      })
    },

    reconnectZoomIOS () {
      this.showConfirmDialog(
        'Zoomと再接続', 
        'Zoomとの連携を再接続して、現在の設定を上書きします。\n実行してよろしいですか？', 
        DIALOG_TYPE.EXECUTE, 
        () => {this.executeConnectZoom('ios')}
      )
    },

    reconnectZoomAndroid () {
      this.showConfirmDialog(
        'Zoomと再接続', 
        'Zoomとの連携を再接続して、現在の設定を上書きします。\n実行してよろしいですか？', 
        DIALOG_TYPE.EXECUTE, 
        () => {this.executeConnectZoom('android')}
      )
    },

    executeConnectZoom (retryType) {
      var zoomAuthURL = location.protocol 
          + '//' 
          + location.host 
          + '/api/user/contact_zoom_auth?retry_type=' + retryType
      console.log('zoom auth url: ' + zoomAuthURL)
      window.location.href = zoomAuthURL
    },

    toggleAttendee(narrativePhoneID) {
      let attendeeIndex = this.attendees.indexOf(narrativePhoneID)
      if (attendeeIndex < 0) {
        this.attendees.push(narrativePhoneID)
      } else {
        this.attendees.splice(attendeeIndex, 1)
      }
    },

    attendeeIncluded (narrativePhoneID) {
      return this.attendees.includes(narrativePhoneID)
    },

    createUniversalLink () {
      let url = process.env.VUE_APP_BASE_URL + '/phone_call'
        + '?sender_id=' + this.user.narrative_phone_id
        + '&attendees=' + this.attendees.join(',')
      if (this.callbackUrl.length > 0) {
        url += '&callback_url=' + this.callbackUrl
      }
      this.universalLinkURL = url
    },

    revokeZoom () {
      this.showConfirmDialog(
        'Zoomと接続解除', 
        'Zoomとの連携を解除して、現在設定されているZoom関係のアカウント情報をすべて削除します。\n実行してよろしいですか？', 
        DIALOG_TYPE.EXECUTE, 
        () => {this.executeRevokeZoom()}
      )
    },

    executeRevokeZoom () {
      User.revoke_zoom().then(res => {
        console.log(res)
        this.showNotifyDialog('Zoom連携解除', 'Zoomとの連携情報が削除されました。')
        this.loadUser()
      }).catch(err => {
        console.error(err)
        this.showNotifyDialog('Zoom連携解除失敗', 'Zoomとの連携情報の削除に失敗しました。')
        this.loadUser()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mediaQuery.scss';
@import '@/assets/css/colorTable.scss';
.user-config-home-view {
  .header {
    display: flex;
    justify-content: space-between;
    padding: 3px 5px;
    border-bottom: 1px solid #ddd;
    .long-logo {
      height: 30px;
    }
    .small-logo {
      display: none;
      height: 30px;
    }
    .signin-state {
      display: flex;
      align-items: center;
      .signin-name {
        margin-right: 10px;
        font-weight: bold;
      }
      .signout-btn {
        padding: 3px;
        border: none;
        border-radius: 5px;
        background-color: $phone-main-color;
        color: #fff;
      }
    }
    @include mediaQuery('phone') {
      .long-logo {
        display: none;
      }
      .small-logo {
        display: inline;
      }
    }
  }
  .content-block {
    padding: 20px 40px;
    h2 {
      margin: 10px 0;
      font-size: 21px;
      text-align: center;
      color: #666;
    }
    h3 {
      margin: 5px 0;
      font-size: 18px;
      color: #666;
    }
    .line-block {
      border: 1px solid #ddd;
      margin-bottom: 10px;
      padding: 10px;
      .line-items {
        display: flex;
        padding: 5px 0;
        span {
          display: inline-block;
        }
        .title {
          margin-right: 10px;
          width: 250px;
          text-align: right;
        }
        .value {
          font-weight: bold;
        }
      }
      .controll {
        display: flex;
        justify-content: flex-end;
        button {
          display: inline-block;
          border: none;
          border-radius: 5px;
          margin: 0 10px;
          padding: 5px 10px;
          font-weight: bold;
          &.destroy-btn {
            background-color: $danger-color;
            color: #fff;
          }
          &.edit-btn {
            background-color: $warning-color;
            color: #fff;
          }
          &.change-password-btn {
            background-color: #666;
            color: #fff;
          }
        }
      }
    }
    @include mediaQuery('phone') {
      padding: 10px;
      .line-block .line-items .title {
        width: 150px;
      }
    }
  }
  .devices {
    padding: 0 40px 20px;
    h2 {
      margin: 10px 0;
      font-size: 21px;
      text-align: center;
      color: #666;
    }
    h3 {
      margin: 5px 0;
      font-size: 18px;
      color: #666;
    }
  }
}
</style>