import { createRouter, createWebHistory } from 'vue-router'
import LandingView from '@/views/LandingView.vue'
import NewUserView from '@/views/NewUserView.vue'
import UserSelectAccountView from '@/views/UserSelectAccountView.vue'
import PresentUserSelectView from '@/views/PresentUserSelectView.vue'
import ConnectZoomAccountView from '@/views/ConnectZoomAccountView.vue'
import TermOfUseView from '@/views/TermOfUseView.vue'
import PrivacyPolicyView from '@/views/PrivacyPolicyView.vue'
import ZoomDocumentView from '@/views/ZoomDocumentView.vue'
import ContactView from '@/views/ContactView.vue'
import AppDownloadView from '@/views/AppDownloadView.vue'
import UserSigninView from '@/views/UserSigninView.vue'
import NbSigninView from '@/views/NbSigninView.vue'
import UserConfigHomeView from '@/views/UserConfigHomeView.vue'
import ReconnectZoomAccountView from '@/views/ReconnectZoomAccountView.vue'
import UserManualView from '@/views/UserManualView.vue'

const routes = [
  {
    path: '/',
    name: 'landing',
    component: LandingView
  },
  {
    path: '/user_config',
    name: 'UserConfig',
    component: UserSigninView
  },
  {
    path: '/nb_signin',
    name: 'NBSignin',
    component: NbSigninView
  },
  {
    path: '/user_config_home',
    name: 'UserConfigHome',
    component: UserConfigHomeView
  },
  {
    path: '/user/new_user_with_device',
    name: 'newUserWithDevice',
    component: NewUserView
  },
  {
    path: '/user/select_account',
    name: 'userSelectAccount',
    component: UserSelectAccountView,
  },
  {
    path: '/user/select_user',
    name: 'userSelectUser',
    component: PresentUserSelectView
  },
  {
    path: '/user/connect_zoom_account',
    neme: 'userConnectZoomAccount',
    component: ConnectZoomAccountView
  },
  {
    path: '/user/reconnect_zoom_account',
    nema: 'userReconnectZoomAccount',
    component: ReconnectZoomAccountView
  },
  {
    path: '/term_of_use/:lang*',
    name: 'termOfUse',
    component: TermOfUseView,
    props: true
  },
  {
    path: '/privacy_policy/:lang*',
    name: 'privacyPolicy',
    component: PrivacyPolicyView,
    props: true
  },
  {
    path: '/zoom_document/:lang*',
    name: 'zoomDocument',
    component: ZoomDocumentView,
    props: true
  },
  {
    path: '/user_manual/:lang*',
    name: 'userManual',
    component: UserManualView,
  },
  {
    path: '/contact/:lang*',
    name: 'contact',
    component: ContactView,
    props: true
  },
  {
    path: '/app_download/:lang*',
    name: 'appDownload',
    component: AppDownloadView,
    porps: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
